import { IconButton, Snackbar } from "@mui/material";
import React from "react";
import { BiError } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { MdOutlineError } from "react-icons/md";
import { TiTickOutline } from "react-icons/ti"
const defaultErrorMsg = "Something went wrong. Please try again after some time.";
const anchorOrigin = { vertical: "top", horizontal: "right" };
class CustomAlert extends React.Component {
    handleClass = (type) => {
        switch (type) {
            case "error":
                return "errorClass";
            case "success":
                return "successClass";
            default:
                return "defaultClass";
        }
    }
    handleIcon = (type) => {
        switch (type) {
            case "error":
                return <MdOutlineError style={{ marginTop: 4, height: 25, width: 30 }} />;
            case "success":
                return <TiTickOutline style={{ marginTop: 4, height: 25, width: 30 }} />;
            default:
                return <BiError style={{ marginTop: 4, height: 25, width: 30 }} />;
        }
    }

    render() {
        const { type, message, open, callback, duration, top } = this.props;
        return (
            <div className="alert" style={{ top: top ?? 0 }}  >
                <Snackbar
                    autoHideDuration={duration ? duration : 3500}
                    open={open}
                    key={"error-snackbar"}
                    anchorOrigin={anchorOrigin}
                    onClose={callback}
                    className="AlertSnackbar"
                    style={{ marginTop: 50 }}
                >
                    <div style={{ fontSize: 12 }} className={`CustomAlert ${this.handleClass(type)}`}>
                        {this.handleIcon(type)}
                        {message
                            ? message
                            : defaultErrorMsg}
                        <IconButton size="small" aria-label="close" color="inherit" onClick={callback}>
                            <IoMdClose fontSize="small" />
                        </IconButton>
                    </div>

                </Snackbar>
            </div>
        );
    }
}

const styles = {
    alertContainer: {
        top: 150,
    }
}

export default CustomAlert;