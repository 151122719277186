import axios from "axios";
import createEnvironment from "../globel";
import { getLocalStorage } from "../utils/localStorage";


const ENV_VAR = createEnvironment();

const BASE_URL = ENV_VAR.BASE_URL;
const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error && error.status === 401) {
            console.log('Unauthorized. Redirecting to login page...');
        }
        return Promise.reject(error);
    }
);

export const PATHS = {
    LOGIN: BASE_URL + "/auth/login/crm",
    GET_CURRENT_USER_DETAIL: BASE_URL + "/auth/lms/lmsUserDetails",

    ADMIN_USER_LISTING: BASE_URL + "/auth/lms/listUser",
    ADMIN_USER_DETAIL: (id) => BASE_URL + "/auth/lms/getUser/" + id,
    ADMIN_ADD_USER: BASE_URL + "/auth/lms/addUser",
    ADMIN_EDIT_USER: (id) => BASE_URL + "/auth/lms/editUser/" + id,


    DOWNLOAD_LOAN_CSV_SAMPLE: (id) => BASE_URL + "/lms/app/v1/loan/" + id + "/statement",

    UPLOAD_EXCEL_FILE: (type) => BASE_URL + `/app/tdf/processExcel${type}`,

    // process admin
    PROCESS_ADMIN_LISTING: (type) => BASE_URL + `/app/tdf/v2/${type}ExcelMetaDataList`,
    PROCESS_ADMIN_RECORD_LISTING_WITH_FILE_ID: (type) => BASE_URL + `/app/tdf/v2/get${type}DataSummary`,

    // file id details /app/tdf/loanExcelMetaData/24fa1a7393d247ec833e822f0ee22e52
    FILE_ID_DETAILS: (type, id) => BASE_URL + `/app/tdf/${type}ExcelMetaData/${id}`,
    // get all record listing 
    EXCEL_ALL_RECORD_LISTING: (type) => BASE_URL + `/app/tdf/${type}DataSummaryListing`,
    //lms role listing
    CRM_ROLE_LISTING: BASE_URL + "/auth/lms/roleListing",
    CRM_ROLE_REPORTING_TO_LISTING: BASE_URL + "/auth/lms/reportingToListing",
    CRM_ROLE_TEAM_LEAD_LISTING: BASE_URL + "/auth/lms/teamLeadListing",
    CRM_ROLE_ASSIGN_TEAM_LEAD_LISTING: BASE_URL + "/auth/lms/allocationListing",
    CRM_ROLE_ASSIGN_TO_TEAM_LEADS: (type) => BASE_URL + `/app/tdf/${type}TeamLeadAllocation`,
    CRM_ROLE_RESET_TO_TEAM_LEADS: (type, id) => BASE_URL + `/app/tdf/reset${type === "loan" ? "Loan" : "Card"}TeamLeadAllocation/${id}`,

    CRM_ROLE_ASSIGN_TO_TELE_CALLER_LISTING: (type) => BASE_URL + `/app/tdf/listingFor${type}TeamLead`,
    CRM_ROLE_ASSIGN_TO_TELE_CALLERS: (type) => BASE_URL + `/app/tdf/${type}TeleCallerAllocation`,
    CRM_ROLE_RESET_TO_TELE_CALLERS: (type) => BASE_URL + `/app/tdf/reset${type === "loan" ? "Loan" : "Card"}TeleCallerAllocation`,

    // telecaller 
    TELE_CALLER_RECORD_LISTING: (type) => BASE_URL + `/app/tdf/listingFor${type === "loan" ? "Loan" : "Card"}TeleCaller`,
    TELE_CALLER_RECORD_DETAIL: (type, id) => BASE_URL + `/app/tdf/v2/${type}DataSummaryDetail/${id}`,

    // disposition
    TELE_CALLER_SAVE_DISPOSITION: BASE_URL + `/app/tdf/saveDisposition`,
    TELE_CALLER_GET_DISPOSITION_LIST: (id) => BASE_URL + `/app/tdf/getDisposition/${id}`,

    // other record
    TELE_CALLER_OTHER_RECORD: (id) => BASE_URL + `/app/tdf/getOtherRecords/${id}`,


    // tdf website users
    ADMIN_TDF_APP_USERS_LIST: BASE_URL + "/tdf/api/v2/profileListing",
    ADMIN_TDF_APP_STAGE_COUNT: BASE_URL + "/tdf/api/totalActivityStatusLogging",
    ADMIN_TDF_APP_STAGE_COUNT_BY_DATE: BASE_URL + "/tdf/api/activityStatusLogging",
    ADMIN_TDF_VIEW_JOURNEY: (mob) => BASE_URL + "/tdf/api/getListOfActivity/" + mob,


    // download sample csv
    DOWNLOAD_SAMPLE_FORMAT: (type) => BASE_URL + `/app/tdf/${type}DataFormat`,

    TDF_USER_CREDIT_REPORT_FETCH: (mob) => BASE_URL + "/tdf/api/getReport/" + mob,

    // telecaller dashboard
    TDF_TELECALLER_DASHBOARD_DISPOSITION_LISTING: BASE_URL + "/app/tdf/dispositionList",
    TDF_TELECALLER_DASHBOARD_CARDS: BASE_URL + "/app/tdf/teleCallerCardDetails",
    TDF_TELECALLER_DASHBOARD_AGENT_TASK: (type) => BASE_URL + `/app/tdf/agent${type}Tasks`,
    TDF_TELECALLER_VIEW_SEND_SMS: (mob) => BASE_URL + `/tdf/api/sendSmsCRM/${mob}`,


    TDF_WEBSITE_USER_DETAIL: (mob) => BASE_URL + `/tdf/api/getProfileDetail/${mob}`,


    // raise settlement 
    TELE_CALLER_RAISE_SETTLEMENT: BASE_URL + "/tdf/api/raiseSettlement",
    TELE_CALLER_SETTLEMENT_LIST: (mob) => BASE_URL + "/tdf/api/settlementListing/" + mob,
    TELE_CALLER_UPLOAD_SETTLEMENT_LETTER: BASE_URL + "/tdf/api/uploadSettlementLetter",

    // download csv samples
    PROCESS_ADMIN_DOWNLOAD_SAMPLE_CSV: (type) => BASE_URL + `/app/tdf/${type}DataFormat`,

    // campaign apis
    CAMPAIGN_GENRATE_URL: BASE_URL + "/tdf/api/campaign/generateCampaignUrl",
    CAMPAIGN_GENRATED_URL_LISTING: BASE_URL + "/tdf/api/campaign/campaignListing",
    // Post API for UTM
    UTM_GENRATED_URL_LISTING: BASE_URL + "/tdf/api/utm/listing",

    CAMPAIGN_DELETE_BY_ID: (id) => BASE_URL + "/tdf/api/campaign/deleteCampaign/" + id,


    // BLOG APIS
    CREATE_BLOG_FOR_TDF: BASE_URL + "/tdf/api/blog/addBlog",
    BLOG_LISTING_FOR_TDF: BASE_URL + "/tdf/api/blog/blogListing",
    BLOG_DETAIL_FOR_TDF: (id) => BASE_URL + "/tdf/api/blog/blogDetail/" + id,
    BLOG_EDIT_FOR_TDF: (id) => BASE_URL + "/tdf/api/blog/editBlog/" + id,


    // tdf user allocation process
    TDF_USER_LISTING_BY_MAIL: BASE_URL + "/tdf/api/profile/getProfileListingByAgentEmail",
    TDF_USER_ALLOCATION_TO_TELE_CALLER_BY_TL: BASE_URL + "/tdf/api/profile/teleCallerAllocation",
    TDF_USER_ALLOCATION_TO_TL_BY_ADMIN: BASE_URL + "/tdf/api/profile/teamLeadAllocation",
    TDF_USER_ALLOCATION_TL_TO_TC_FILE_ID: BASE_URL + "/tdf/api/profile/profileDataForTeamLead",

    TDF_USER_ALLOCATION_ADMIN_TO_TL_FILE_ID: BASE_URL + "/tdf/api/profile/profileDataForAdmin",

    TDF_USER_RE_ALLOCATION_TO_TELE_CALLER_BY_TL: BASE_URL + "/tdf/api/profile/reAllocationTeleCaller",


    TDF_USER_RE_ALLOCATION_TO_TEAM_LEAD_BY_ADMIN: BASE_URL + "/tdf/api/profile/reAllocationTeamLead",
    TDF_USER_RESET_TO_TEAM_LEAD_BY_ADMIN: (fileId) => BASE_URL + "/tdf/api/profile/resetTeamLeadAllocation/" + fileId,

    TDF_USER_RESET_TO_TELE_CALLER_BY_TL: (id) => BASE_URL + "/tdf/api/profile/resetTeleCallerAllocation/" + id,
    TDF_USER_DETAIL_LIST_TO_TELE_CALLER_BY_TL: BASE_URL + "/tdf/api/profile/profileDataDetailForTeamLead",

    TDF_USER_DETAIL_LIST_TO_TEAM_LEAD_BY_ADMIN: BASE_URL + "/tdf/api/profile/profileDataDetailForAdmin",

    TDF_USER_BLOCK_DETAIL_TO_TELE_CALLER_BY_TL: (mob) => BASE_URL + "/tdf/api/profile/getProfileDetail/" + mob,


    TDF_PLATFORM_SAVE_DISPOSTION: BASE_URL + "/tdf/api/disposition/saveDisposition",
    TDF_PLATFORM_DISPOSTION_LISTING: (mob) => BASE_URL + "/tdf/api/disposition/getDisposition/" + mob,

    // team lead dahsboard
    TEAM_LEAD_DASHBOARD_LISTING: BASE_URL + "/app/teamLead/agentsSummary",
    TEAM_LEAD_DASHBOARD_STATS: BASE_URL + "/app/teamLead/teamLeadSummary",
    TEAM_LEAD_DASHBOARD_TC_DETAIS: BASE_URL + "/app/teamLead/agentDetails",


    // telecaller platform alloc stats
    PLATFORM_GEN_ALLOC_STATS: (agent) => BASE_URL + "/tdf/api/stats/v2/" + agent,
    PLATFORM_GEN_ALLOC_STATS_FILTERS: (agent) => BASE_URL + "/tdf/api/stats/v2/" + agent + "/filter",
    PLATFORM_ALLOCATION_DISPOSTIONS_STAT: BASE_URL + "/tdf/api/stats/disposition",
    PLATFORM_ALLOCATION_STAT_AGENT_SUMMARY: BASE_URL + "/tdf/api/stats/teamLead/agentsSummary",

    PLATFORM_DISPOSITIONS_DOWNLOAD_BY_TL: BASE_URL + "/tdf/api/download/dispositionByAgent",
    PLATFORM_DISPOSITIONS_TABLE_VIEW: BASE_URL + "/tdf/api/stats/disposition",

    // admin dashboard screen
    PLATFORM_ADMIN_TL_SUMMARY: BASE_URL + "/tdf/api/stats/admin/teamLeadSummary",
    PLATFORM_ADMIN_BADLOAN_LISTING: BASE_URL + "/tdf/api/badLoans/getBadLoans",
    PLATFORM_ADMIN_BADLOAN_DOWNLOAD: BASE_URL + "/tdf/api/download/badLoans",
    PLATFORM_ADMIN_STAGE_STATUS_LOGGING: BASE_URL + "/tdf/api/profile/statusLogging",
    PLATFORM_ADMIN_ACTION_LOGGING: BASE_URL + "/tdf/api/profile/actionLogging",

    // platform data list downloads
    PLATFORM_METADATA_LIST_DOWNLOAD_BY_TL: BASE_URL + "/tdf/api/download/profileTCAllocationMetaData",
    PLATFORM_METADATA_LIST_DETAIL_DOWNLOAD_BY_TL: BASE_URL + "/tdf/api/download/profileTCAllocationMetaDataDetail",
    PLATFORM_METADATA_LIST_DOWNLOAD_BY_ADMIN: BASE_URL + "/tdf/api/download/profileAllocationMetaData",
    PLATFORM_METADATA_LIST_DETAIL_DOWNLOAD_BY_ADMIN: BASE_URL + "/tdf/api/download/profileAllocationMetaDataDetail",


    // campaign apis
    CAMPAIGN_GET_ALL_SMS_TEMPLATE: BASE_URL + "/tdf/api/dltTemplate/getAll",
    CAMPAIGN_UPLOAD_EXCEL_FILE: BASE_URL + "/tdf/api/campaign/runCampaign",
    CAMPAIGN_APPROVE_REJECT_BY_ADMIN: BASE_URL + "/tdf/api/campaign/approveCampaign",
    CAMPAIGN_RESCHEDULE_BY_ADMIN: BASE_URL + "/tdf/api/campaign/rescheduleCampaign",

    CAMPAIGN_GET_LIST_BY_FILTERS: BASE_URL + "/tdf/api/profile/getProfileCampaignListingByAgentEmail",
    CAMPAIGN_CREATE_CAMPAIGN_BY_PLATFORM_LIST: BASE_URL + "/tdf/api/campaign/runAllocationCampaign",
    CAMPAIGN_DETAIL_BY_ID: (id) => BASE_URL + "/tdf/api/campaign/getCampaign/" + id,
    CAMPAIGN_GET_LIST_BY_FILTERS_CLIENT_ALLOCATION: (type) => BASE_URL + `/app/tdf/${type}DataSummaryCampaignListing`,
    CAMPAIGN_SUMMARIZED_DETAIL_BY_ID: (id) => `${BASE_URL}/tdf/api/campaign/summarizedCampaignReport?campaignId=${id}`,
    CAMPAIGN_FULL_DETAIL_LIST: `${BASE_URL}/tdf/api/campaign/detailedCampaignReport`,

    // matrix connected attemped
    ADMIN_MATRIX_CHART: BASE_URL + "/tdf/api/metrics/fetchMetrics",
    // list of tc to admin
    ADMIN_LIST_OF_TELE_CALLERS_ADMIN: BASE_URL + "/auth/lms/reportingToAgent",


    // UTM Post API
    UTM_UPLOAD: BASE_URL + "/tdf/api/utm/generateUtmUrl",

    // campiagn template upload
    CAMPAIGN_SMS_LIST: BASE_URL + "/tdf/api/dltTemplate/getAll",
    CAMPAIGN_SMS_ADD_TEMPLATE: BASE_URL + "/tdf/api/dltTemplate/addTemplate",
    CAMPAIGN_SMS_ADD_BULK_TEMPLATE: BASE_URL + "/tdf/api/dltTemplate/addBulkTemplates",
    CAMPAIGN_EMAIL_LIST: BASE_URL + "/tdf/api/gupshup/email/getAll",
    CAMPAIGN_EMAIL_ADD_TEMPLATE: BASE_URL + "/tdf/api/gupshup/email/addTemplate",
    CAMPAIGN_EMAIL_TEMPLATE_DETAIL: (id) => `${BASE_URL}/tdf/api/gupshup/email/getTemplate/${id}`,
    CAMPAIGN_EMAIL_TEMPLATE_DELETE: (id) => `${BASE_URL}/tdf/api/gupshup/email/deleteTemplate/${id}`,
    CAMPAIGN_EMAIL_DETAIL_DOWNLOAD: (path) => `${BASE_URL}/tdf/api/download/file?path=${path}`,

    CAMPAIGN_GET_CAMPAIGN_HISTORY: `${BASE_URL}/tdf/api/campaign/getCampaignHistory`,
    CAMPAIGN_RESCHEDULE: `${BASE_URL}/tdf/api/campaign/rescheduleCampaign`,

    // credilio
    CREDILIO_DETAIL: (mob) => `${BASE_URL}/tdf/api/credilio/getDetails/${mob}`,

    // campaign reporting dashb
    CAMPAIGN_REPORTING_DASHBOARD_CHANNEL_STATS: `${BASE_URL}/tdf/api/campaign/getCampaignChannelStats`,


    // dashboard stats
    DASHBOARD_API_TEAMLEAD_SUMMARY: `${BASE_URL}/tdf/api/stats/admin/teamLeadSummary`,
    DASHBOARD_API_ADMIN_SUMMARY: `${BASE_URL}/tdf/api/stats/admin/adminSummary`,


    CAMPAIGN_REPORTING_DASHBOARD_TREADLINE: `${BASE_URL}/tdf/api/campaignStats/getStats`,
    GET_STATE_LIST: `${BASE_URL}/tdf/api/getState`
}



const authorizeRequest = () => {
    const defaultHeaders = {};
    // const AUTH_RESPONSE = GetCookie('USER_AUTH');
    const AUTH_RESPONSE = getLocalStorage('USER_AUTH');
    if (AUTH_RESPONSE) {
        return {
            'Authorization': `Bearer ${AUTH_RESPONSE}`
        }
    } else return defaultHeaders;

}

export const API = {

    GET: (url, body = {}, params = {}, cb) => {
        axiosInstance.get(url, {
            headers: authorizeRequest(),
            data: body,
            params: params
        }).then(res => {
            cb(res.data);
        }).catch(err => {
            console.error("ERROR:", err);
            cb(null);
        })
    },
    POST: (url, body = {}, params = {}, cb) => {
        axiosInstance.post(url,
            body, {
            headers: authorizeRequest(),
            params: params
        }).then(res => {
            cb(res.data);
        }).catch(err => {
            console.log("ERROR: ", err);
            cb(null);
        })
    },
    PATCH: (url, body = {}, params = {}, cb) => {
        axiosInstance.patch(url,
            body, {
            headers: authorizeRequest(),
            params: params
        }).then(res => {
            cb(res.data);
        }).catch(err => {
            console.log("ERROR: ", err);
            cb(null);
        })
    },
    DELETE: (url, body = {}, cb) => {
        axiosInstance.delete(url, {
            headers: authorizeRequest(),
            data: body
        }).then(res => {
            cb(res);
        }).catch(err => {
            console.error("ERROR: ", err);
            cb(null);
        })
    },
    GENERAL: (url, method = "get", body = {}, params = {}, headers = {}, cb) => {
        axios({
            url: url,
            method: method,
            data: body,
            params: params,
            headers: headers
        }).then(res => {
            cb(res.data);
        }).catch(err => {
            console.error("ERROR: ", err);
            cb(err);
        })
    },
    DOWNLOAD: (url, body = {}, params = {}, cb) => {
        axiosInstance.get(url, {
            responseType: 'blob',
            headers: authorizeRequest(),
            data: body,
            params: params
        })
            .then((response) => {
                cb(response)
            })
            .catch((err) => {
                console.log(err);
            });
    },
    DOWNLOADWITHHEAD: (url, headers = {}, body = {}, params = {}, cb) => {
        axios({
            url: url,
            method: 'POST',
            responseType: "blob", // important
            headers: headers,
            data: body
        })
            .then((response) => {
                cb(response)
            })
            .catch((err) => {
                console.log(err);
            });
    },
    DOWNLOADPOST: (url, body = {}, params = {}, cb) => {
        axios({
            url: url,
            method: 'POST',
            responseType: 'blob', // important
            headers: authorizeRequest(),
            data: body
        }).then((response) => {
            cb(response);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'template.xlsx');
            document.body.appendChild(link);
            link.click()
        }).catch((err) => {
            console.log(err);
        });
    },

}