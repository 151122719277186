import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;







// import React, { createContext,useContext,useEffect,useState} from "react";

// const AuthContext = createContext();

// const initialState = {
//     isLogged:false
// }

// export const ContextProvider = ({children})=>{
//     const [isLogged, setIsLogged] = useState(true);
//     const handleClick = (clicked)=>{
//         setIsClicked({...initialState,[clicked]:true})
//     }
    
//     return (
//       <AuthContext.Provider
//          value = {{ 
//             activeMenu,
//             setActiveMenu,
//         }}
//       >
//        {children}
//       </AuthContext.Provider>
//   )
// }

// export const useAuthContext = ()=> useContext(AuthContext)
// // import { createContext ,useReducer} from "react";
// // export const AuthContext = createContext();

// // export const authReducer = (state,action)=>{
// //     switch(action.type){
// //         case "LOGIN":
// //             return { user:action.payload};
// //         case "LOGOUT":
// //             return { user:null};
// //         default:
// //             return state;
// //     }
// // }

// // export const AuthContextProvider = ({children})=>{
// //     const [state,dispatch] = useReducer(authReducer,{
// //         user:null
// //     })
// //     console.log("AuthContext state ",state);

// //     return (
// //         <AuthContext.Provider value={{...state,dispatch}}>
// //             {children }
// //         </AuthContext.Provider>
// //     )
// // }