import React, { useEffect } from 'react';
import { HiMenuAlt1 } from 'react-icons/hi';
import { UserProfile } from '.';
import { useStateContext } from '../contexts/ContextProvider';
import logo from "../data/logo/tdfLogo.png";
import "../style/navbar.css"
import { FaUserAlt } from 'react-icons/fa';

function Navbar() {
  const { activeMenu, setActiveMenu, isClicked, setIsClicked, handleClick, screenSize, setScreenSize } = useStateContext();
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [screenSize]);
  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else { setActiveMenu(true) }
  }, [screenSize])
  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
      setIsClicked(false);
    } else {
      setIsClicked(false);
    }
  };

  return (<>
    <nav style={{ display: "grid", alignItems: "center" }}
      className={`bg-white px-2 sm:px-4 py-2.5 dark:bg-gray-900 fixed ${activeMenu ? 'w-72 sidebar bg-white' : " w-0"} z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600`}
    >
      <div style={{ position: "absolute", marginTop: 0, fontSize: 20, marginLeft: 10 }}>
        <button onClick={() => {
          setActiveMenu((prevActiveMenu) => !prevActiveMenu);
          setIsClicked(false);
        }}><HiMenuAlt1></HiMenuAlt1></button>
      </div>
      <div className="CustomNavBar">
        <a to="/home" className="flex items-center">
          <div className="flex justify-between items-center">
            <div className="flex flex-col items-center justify-center">
              <img style={{ height: 30, width: 150 }} src={logo} alt="logo" />
            </div>
          </div>
        </a>
        <div style={{ marginLeft: 10 }} className="flex">
          <div
            className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
            onClick={() => handleClick('userProfile')}
          >
            <FaUserAlt style={{ height: 25, width: 25 }} className="rounded-full w-8 h-8" />
          </div>
          {isClicked.userProfile && (<UserProfile />)}
        </div>
      </div>
    </nav>

  </>)
}

export default Navbar