
import {
    PATHS,
    API
} from "./constants";

export function LOGIN_API(data, headers, cb) {
    API.GENERAL(PATHS.LOGIN, 'POST', data, {}, headers,
        async (res) => {
            const response = await res;
            console.log("login response is: ", response);
            cb(response);
        }
    )
}
/// admin
export function getUserListing(params, cb) {
    API.POST(PATHS.ADMIN_USER_LISTING, params, {},
        function (res) {
            cb(res);
        }
    )
}

export function getUserDetail(id, cb) {
    API.GET(PATHS.ADMIN_USER_DETAIL(id), {}, {},
        function (res) {
            cb(res);
        }
    )
}

export function addAdminUser(params, cb) {
    API.POST(PATHS.ADMIN_ADD_USER, params, {},
        function (res) {
            cb(res);
        }
    )
}

export function editAdminUser(id, params, cb) {
    API.PATCH(PATHS.ADMIN_EDIT_USER(id), params, {},
        function (res) {
            cb(res);
        }
    )
}

export function getCurrentUserDetail(cb) {
    API.GET(PATHS.GET_CURRENT_USER_DETAIL, {}, {},
        function (res) {
            cb(res)
        }
    )
}

// download sample .csv
export function downloadCSVSSampleFileLoans(id, cb) {
    API.DOWNLOAD(PATHS.DOWNLOAD_LOAN_CSV_SAMPLE(id), {}, {},
        function (res) {
            console.log("Downloading loan statement ", res);
            cb(res);
        })
}


// upload excel
export function postUploadExcelFile(type, data, headers, cb) {
    API.GENERAL(PATHS.UPLOAD_EXCEL_FILE(type), 'POST', data, {}, headers,
        function (res) {
            console.log("uploaded: ", res);
            cb(res);
        }
    )
}



// apis for process admin listing 
export function getProcessExcelListing(params, type, cb) {
    API.POST(PATHS.PROCESS_ADMIN_LISTING(type), params, {},
        function (res) {
            cb(res);
        }
    )
}

export function getRecordListingWithFileId(params, type, cb) {
    API.POST(PATHS.PROCESS_ADMIN_RECORD_LISTING_WITH_FILE_ID(type), params, {},
        function (res) {
            cb(res);
        }
    )
}

// file id details 
export function getFileIdDetails(type, id, cb) {
    API.GET(PATHS.FILE_ID_DETAILS(type, id), {}, {},
        function (res) {
            cb(res)
        }
    )
}

// view all records
export function getAllExcelRecordListing(params, type, cb) {
    API.POST(PATHS.EXCEL_ALL_RECORD_LISTING(type), params, {},
        function (res) {
            cb(res);
        }
    )
}


// lms roles listing 
export function getLMSRoleListing(cb) {
    API.GET(PATHS.CRM_ROLE_LISTING, {}, {},
        function (res) {
            cb(res)
        }
    )
}
export function getLMSReportingToListing(cb) {
    API.GET(PATHS.CRM_ROLE_REPORTING_TO_LISTING, {}, {},
        function (res) {
            cb(res)
        }
    )
}

export function getLMSTeamLeadListing(cb) {
    API.GET(PATHS.CRM_ROLE_TEAM_LEAD_LISTING, {}, {},
        function (res) {
            cb(res)
        }
    )
}

export function getLMSAssignTeamLeadListing(cb) {
    API.GET(PATHS.CRM_ROLE_ASSIGN_TEAM_LEAD_LISTING, {}, {},
        function (res) {
            cb(res)
        }
    )
}


// assign to team leads
export function postAssignToTeamLeads(params, type, cb) {
    API.POST(PATHS.CRM_ROLE_ASSIGN_TO_TEAM_LEADS(type), params, {},
        function (res) {
            cb(res);
        }
    )
}

export function postResetToTeamLeads(id, type, cb) {
    API.POST(PATHS.CRM_ROLE_RESET_TO_TEAM_LEADS(type, id), {}, {},
        function (res) {
            cb(res);
        }
    )
}

// team leads listing and allocation
export function getTeamLeadListing(params, type, cb) {
    API.POST(PATHS.CRM_ROLE_ASSIGN_TO_TELE_CALLER_LISTING(type), params, {},
        function (res) {
            cb(res);
        }
    )
}

export function postAssignToTeleCallers(params, type, cb) {
    API.POST(PATHS.CRM_ROLE_ASSIGN_TO_TELE_CALLERS(type), params, {},
        function (res) {
            cb(res);
        }
    )
}

export function postResetToTeleCallers(type, cb) {
    API.POST(PATHS.CRM_ROLE_RESET_TO_TELE_CALLERS(type), {}, {},
        function (res) {
            cb(res);
        }
    )
}

// tele caller view 
export function getTeleCallerRecordListing(params, type, cb) {
    API.POST(PATHS.TELE_CALLER_RECORD_LISTING(type), params, {},
        function (res) {
            cb(res);
        }
    )
}

export function getTeleCallerRecordDetail(type, id, cb) {
    API.GET(PATHS.TELE_CALLER_RECORD_DETAIL(type, id), {}, {},
        function (res) {
            cb(res)
        }
    )
}

// dispositions
export function postTeleCallerDisposition(params, cb) {
    API.POST(PATHS.TELE_CALLER_SAVE_DISPOSITION, params, {},
        function (res) {
            cb(res);
        }
    )
}

export function getTeleCallerDispositionList(id, cb) {
    API.GET(PATHS.TELE_CALLER_GET_DISPOSITION_LIST(id), {}, {},
        function (res) {
            cb(res)
        }
    )
}


// other record
export function postTeleCallerOtherRecord(id, cb) {
    API.POST(PATHS.TELE_CALLER_OTHER_RECORD(id), {}, {},
        function (res) {
            cb(res);
        }
    )
}

// tdf website user

export function getTdfRecordListing(params, cb) {
    API.POST(PATHS.ADMIN_TDF_APP_USERS_LIST, params, {},
        function (res) {
            cb(res);
        }
    )
}

// tdf website counts

export function getTdfRecordCounts(cb) {
    API.GET(PATHS.ADMIN_TDF_APP_STAGE_COUNT, {}, {},
        function (res) {
            cb(res)
        }
    )
}
export function getTdfUserJourney(mob, cb) {
    API.GET(PATHS.ADMIN_TDF_VIEW_JOURNEY(mob), {}, {},
        function (res) {
            cb(res)
        }
    )
}

export function postTdfRecordCountsByDate(params, cb) {
    API.POST(PATHS.ADMIN_TDF_APP_STAGE_COUNT_BY_DATE, params, {},
        function (res) {
            cb(res);
        }
    )
}

// download csv downalod

export function getSampleCSVDownload(type, cb) {
    API.GET(PATHS.DOWNLOAD_LOAN_CSV_SAMPLE(type), {}, {},
        function (res) {
            cb(res)
        }
    )
}


// credit report fetch
export function getTdfUserCreditReport(mob, cb) {
    API.GET(PATHS.TDF_USER_CREDIT_REPORT_FETCH(mob), {}, {},
        function (res) {
            cb(res)
        }
    )
}

// telecaller dashboard 


export function postTdfTelecallerDashboardDisposition(params, cb) {
    API.POST(PATHS.TDF_TELECALLER_DASHBOARD_DISPOSITION_LISTING, params, {},
        function (res) {
            cb(res);
        }
    )
}

export function postTdfTelecallerDashboardCards(params, cb) {
    API.GET(PATHS.TDF_TELECALLER_DASHBOARD_CARDS, {}, {},
        function (res) {
            cb(res)
        }
    )
}


export function postTdfTelecallerSendSMS(mob, cb) {
    API.POST(PATHS.TDF_TELECALLER_VIEW_SEND_SMS(mob), {}, {},
        function (res) {
            cb(res);
        }
    )
}


export function getTdfWebsiteUserDetail(mob, cb) {
    API.GET(PATHS.TDF_WEBSITE_USER_DETAIL(mob), {}, {},
        function (res) {
            cb(res)
        }
    )
}


// raise settlement/settlement apis

export function postTeleCallerRaiseSettlement(params, cb) {
    API.POST(PATHS.TELE_CALLER_RAISE_SETTLEMENT, params, {},
        function (res) {
            cb(res);
        }
    )
}

export function postSettlementList(mob, cb) {
    API.POST(PATHS.TELE_CALLER_SETTLEMENT_LIST(mob), {}, {},
        function (res) {
            cb(res);
        }
    )
}

export function postUploadSettlementLetter(data, headers, cb) {
    API.GENERAL(PATHS.TELE_CALLER_UPLOAD_SETTLEMENT_LETTER, 'POST', data, {}, headers,
        function (res) {
            console.log("uploaded settlement letter:  ", res);
            cb(res);
        }
    )
}

// TELE CALLER DAHSBOARD AGENT TASTK 
export function postTeleCallerDashboardAgentTask(type, params, cb) {
    API.POST(PATHS.TDF_TELECALLER_DASHBOARD_AGENT_TASK(type), params, {},
        function (res) {
            cb(res);
        }
    )
}



// process admin download sample csv 

export function getDownloadSampleCSV(type, cb) {
    API.DOWNLOAD(PATHS.PROCESS_ADMIN_DOWNLOAD_SAMPLE_CSV(type), {}, {},
        function (res) {
            console.log("Downloading csv statement ", res);
            cb(res);
        })
}



// campaign apis
export function postCampaignGenrateUrl(params, cb) {
    API.POST(PATHS.CAMPAIGN_GENRATE_URL, params, {},
        function (res) {
            cb(res);
        }
    )
}

export function postCampaignGenratedUrlListing(params, cb) {
    API.POST(PATHS.CAMPAIGN_GENRATED_URL_LISTING, params, {},
        function (res) {
            cb(res)
        }
    )
}
// Post API for UTM
export function postUtmGenratedUrlListing(params, cb) {
    API.POST(PATHS.UTM_GENRATED_URL_LISTING, params, {},
        function (res) {
            cb(res)
        }
    )
}


export function postDeleteCampaignById(id, cb) {
    API.POST(PATHS.CAMPAIGN_DELETE_BY_ID(id), {}, {},
        function (res) {
            cb(res)
        }
    )
}

// create blog post
export function postCreateBlogForTdfWebsite(data, headers, cb) {
    API.GENERAL(PATHS.CREATE_BLOG_FOR_TDF, 'POST', data, {}, headers,
        function (res) {
            console.log("uploaded blog post: ", res);
            cb(res);
        }
    )
}

export function postBlogListing(params, cb) {
    API.POST(PATHS.BLOG_LISTING_FOR_TDF, params, {},
        function (res) {
            cb(res);
        }
    )
}


export function getBlogDetail(id, cb) {
    API.GET(PATHS.BLOG_DETAIL_FOR_TDF(id), {}, {},
        function (res) {
            cb(res)
        }
    )
}

export function patchBlogDetails(id, data, headers, cb) {
    API.GENERAL(PATHS.BLOG_EDIT_FOR_TDF(id), 'PATCH', data, {}, headers,
        function (res) {
            console.log("blog updated ", res);
            cb(res);
        }
    )
}


// tdf user allocation process
export function postTdfUserListingByMail(params, cb) {
    API.POST(PATHS.TDF_USER_LISTING_BY_MAIL, params, {},
        function (res) {
            cb(res);
        }
    )
}

export function postTdfUserAllocationToTeleByTl(params, cb) {
    API.POST(PATHS.TDF_USER_ALLOCATION_TO_TELE_CALLER_BY_TL, params, {},
        function (res) {
            cb(res);
        }
    )
}

export function postTdfUserAllocationToTlByAdmin(params, cb) {
    API.POST(PATHS.TDF_USER_ALLOCATION_TO_TL_BY_ADMIN, params, {},
        function (res) {
            cb(res);
        }
    )
}

export function postTdfUserAllocationTlToTcFileId(params, cb) {
    API.POST(PATHS.TDF_USER_ALLOCATION_TL_TO_TC_FILE_ID, params, {},
        function (res) {
            cb(res);
        }
    )
}

// admin to tl platform allocation by fileid

export function postTdfUserAllocationAdminToTlFileId(params, cb) {
    API.POST(PATHS.TDF_USER_ALLOCATION_ADMIN_TO_TL_FILE_ID, params, {},
        function (res) {
            cb(res);
        }
    )
}

export function postTdfUserReAllocationToTeleByTl(params, cb) {
    API.POST(PATHS.TDF_USER_RE_ALLOCATION_TO_TELE_CALLER_BY_TL, params, {},
        function (res) {
            cb(res);
        }
    )
}

export function postTdfUserReAllocationToTlByAdmin(params, cb) {
    API.POST(PATHS.TDF_USER_RE_ALLOCATION_TO_TEAM_LEAD_BY_ADMIN, params, {},
        function (res) {
            cb(res);
        }
    )
}


export function postTdfUserResetAllocationToTeleByTl(id, cb) {
    API.POST(PATHS.TDF_USER_RESET_TO_TELE_CALLER_BY_TL(id), {}, {},
        function (res) {
            cb(res);
        }
    )
}


export function postTdfUserResetAllocationToTlByAdmin(id, cb) {
    API.POST(PATHS.TDF_USER_RESET_TO_TEAM_LEAD_BY_ADMIN(id), {}, {},
        function (res) {
            cb(res);
        }
    )
}

export function postTdfUserDetailAllocationToTeleByTl(params, cb) {
    API.POST(PATHS.TDF_USER_DETAIL_LIST_TO_TELE_CALLER_BY_TL, params, {},
        function (res) {
            cb(res);
        }
    )
}

// platform allocation detail for admin
export function postTdfUserDetailAllocationToTlByAdmin(params, cb) {
    API.POST(PATHS.TDF_USER_DETAIL_LIST_TO_TEAM_LEAD_BY_ADMIN, params, {},
        function (res) {
            cb(res);
        }
    )
}
export function postTdfUserBlockDetailsAllocToTeleByTl(mob, cb) {
    API.GET(PATHS.TDF_WEBSITE_USER_DETAIL(mob), {}, {},
        function (res) {
            cb(res)
        }
    )
}

// disposition platform user 
export function postTdfUserPlatformSaveDispostionByTc(params, cb) {
    API.POST(PATHS.TDF_PLATFORM_SAVE_DISPOSTION, params, {},
        function (res) {
            cb(res);
        }
    )
}

export function getTdfUserPlatformListingByTc(mob, cb) {
    API.GET(PATHS.TDF_PLATFORM_DISPOSTION_LISTING(mob), {}, {},
        function (res) {
            cb(res)
        }
    )
}

// team lead apis

export function postTeamLeadDahsboardListing(params, cb) {
    API.POST(PATHS.TEAM_LEAD_DASHBOARD_LISTING, params, {},
        function (res) {
            cb(res)
        }
    )
}

export function getTeamLeadDahsboardStat(cb) {
    API.GET(PATHS.TEAM_LEAD_DASHBOARD_STATS, {}, {},
        function (res) {
            cb(res)
        }
    )
}

export function postTeamLeadDahsboardDetailTc(params, cb) {
    API.POST(PATHS.TEAM_LEAD_DASHBOARD_TC_DETAIS, params, {},
        function (res) {
            cb(res)
        }
    )
}

// telecaller platform alloc stats apis

export function postPlatformStatByAgent(agent, params, cb) {
    API.POST(PATHS.PLATFORM_GEN_ALLOC_STATS(agent), params, {},
        function (res) {
            cb(res)
        }
    )
}
export function postPlatformStatFilterByAgent(agent, params, cb) {
    API.POST(PATHS.PLATFORM_GEN_ALLOC_STATS_FILTERS(agent), params, {},
        function (res) {
            cb(res)
        }
    )
}


export function postPlatformDispositionsStatsByTl(params, cb) {
    API.POST(PATHS.PLATFORM_ALLOCATION_DISPOSTIONS_STAT, params, {},
        function (res) {
            cb(res)
        }
    )
}


export function postPlatformStatsAgentSummaryByTl(params, cb) {
    API.POST(PATHS.PLATFORM_ALLOCATION_STAT_AGENT_SUMMARY, params, {},
        function (res) {
            cb(res)
        }
    )
}

export function downloadGetPlatformDispositionsByTl(headers, params, cb) {
    API.DOWNLOADWITHHEAD(PATHS.PLATFORM_DISPOSITIONS_DOWNLOAD_BY_TL, headers, params, {},
        function (res) {
            console.log("Downloading pltfm dispositions ", res);
            cb(res);
        }
    )

}
// dispostion table 

export function postPlatformDispoitionTableView(params, cb) {
    API.POST(PATHS.PLATFORM_DISPOSITIONS_TABLE_VIEW, params, {},
        function (res) {
            cb(res)
        }
    )
}

// admin dashboard platform screen

export function postPlatformAdminTlSummary(params, cb) {
    API.POST(PATHS.PLATFORM_ADMIN_TL_SUMMARY, params, {},
        function (res) {
            cb(res)
        }
    )
}

export function postPlatformAdminBadloanListing(params, cb) {
    API.POST(PATHS.PLATFORM_ADMIN_BADLOAN_LISTING, params, {},
        function (res) {
            cb(res)
        }
    )
}

// download badloans 
export function downloadAdminBadloan(headers, params, cb) {
    API.DOWNLOADWITHHEAD(PATHS.PLATFORM_ADMIN_BADLOAN_DOWNLOAD, headers, params, {},
        function (res) {
            console.log("Down", res);
            cb(res);
        }
    )
}


// download listings
export function downloadPlatformListByTl(headers, params, cb) {
    API.DOWNLOADWITHHEAD(PATHS.PLATFORM_METADATA_LIST_DOWNLOAD_BY_TL, headers, params, {},
        function (res) {
            cb(res);
        }
    )
}

export function downloadPlatformListDetailByTl(headers, params, cb) {
    API.DOWNLOADWITHHEAD(PATHS.PLATFORM_METADATA_LIST_DETAIL_DOWNLOAD_BY_TL, headers, params, {},
        function (res) {
            cb(res);
        }
    )
}


export function downloadPlatformListByAdmin(headers, params, cb) {
    API.DOWNLOADWITHHEAD(PATHS.PLATFORM_METADATA_LIST_DOWNLOAD_BY_ADMIN, headers, params, {},
        function (res) {
            cb(res);
        }
    )
}

export function downloadPlatformListDetailByAdmin(headers, params, cb) {
    API.DOWNLOADWITHHEAD(PATHS.PLATFORM_METADATA_LIST_DETAIL_DOWNLOAD_BY_ADMIN, headers, params, {},
        function (res) {
            cb(res);
        }
    )
}

// campaign apis 

export function postCampaignGetAllSmsTemplate(params, cb) {
    API.POST(PATHS.CAMPAIGN_GET_ALL_SMS_TEMPLATE, params, {},
        function (res) {
            cb(res)
        }
    )
}

export function postUploadCampaignExcelFile(data, headers, cb) {
    API.GENERAL(PATHS.CAMPAIGN_UPLOAD_EXCEL_FILE, 'POST', data, {}, headers,
        function (res) {
            cb(res);
        }
    )
}
// Utm data upload
export function postUploadUtm(params, cb) {
    API.POST(PATHS.UTM_UPLOAD, params, {},
        function (res) {
            cb(res);
        }
    )
}



export function postCampaignApproveRejectByAdmin(params, cb) {
    API.POST(PATHS.CAMPAIGN_APPROVE_REJECT_BY_ADMIN, params, {},
        function (res) {
            cb(res)
        }
    )
}

// API calling for the reschedule Campaign
export function postrescheduleCampaign(params, cb) {
    API.POST(PATHS.CAMPAIGN_RESCHEDULE_BY_ADMIN, params, {},
        function (res) {
            cb(res)
        }
    )
}

export function postCampaignGetListByFilters(params, cb) {
    API.POST(PATHS.CAMPAIGN_GET_LIST_BY_FILTERS, params, {},
        function (res) {
            cb(res)
        }
    )
}

export function postCampaignCreateCampaignByPlatformList(params, cb) {
    API.POST(PATHS.CAMPAIGN_CREATE_CAMPAIGN_BY_PLATFORM_LIST, params, {},
        function (res) {
            cb(res)
        }
    )
}

export function getCampaignDetailByAdmin(id, cb) {
    API.GET(PATHS.CAMPAIGN_DETAIL_BY_ID(id), {}, {},
        function (res) {
            cb(res);
        }
    )
}

export function getCampaignSummarizedDetail(id, cb) {
    API.GET(PATHS.CAMPAIGN_SUMMARIZED_DETAIL_BY_ID(id), {}, {},
        function (res) {
            cb(res);
        }
    )
}

export function postCampaignDFulldetailList(params, cb) {
    API.POST(PATHS.CAMPAIGN_FULL_DETAIL_LIST, params, {},
        function (res) {
            cb(res)
        }
    )
}



export function postCampaignGetListByFiltersClientAllocation(type, params, cb) {
    API.POST(PATHS.CAMPAIGN_GET_LIST_BY_FILTERS_CLIENT_ALLOCATION(type), params, {},
        function (res) {
            cb(res)
        }
    )
}


// admin dashboard
export function getAdminStageStatusLogging(cb) {
    API.GET(PATHS.PLATFORM_ADMIN_STAGE_STATUS_LOGGING, {}, {},
        function (res) {
            cb(res);
        }
    )
}
export function getAdminStageActionLogging(cb) {
    API.GET(PATHS.PLATFORM_ADMIN_ACTION_LOGGING, {}, {},
        function (res) {
            cb(res);
        }
    )
}

// matrix

export function postAdminTeleperformanceChart(params, cb) {
    API.POST(PATHS.ADMIN_MATRIX_CHART, params, {},
        function (res) {
            cb(res)
        }
    )
}
// list of tc to admin

export function getListOfTelecallersToAdmin(params, cb) {
    API.POST(PATHS.ADMIN_LIST_OF_TELE_CALLERS_ADMIN, params, {},
        function (res) {
            cb(res);
        }
    )
}

// campaign template upload 
export function getCampaignSmsTemplateList(params, cb) {
    API.POST(PATHS.CAMPAIGN_SMS_LIST, params, {},
        function (res) {
            cb(res);
        }
    )
}

export function postAddCampaignSmsTemplate(params, cb) {
    API.POST(PATHS.CAMPAIGN_SMS_ADD_TEMPLATE, params, {},
        function (res) {
            cb(res);
        }
    )
}

export function postUploadSmsTemplate(data, headers, cb) {
    API.GENERAL(PATHS.CAMPAIGN_SMS_ADD_BULK_TEMPLATE, 'POST', data, {}, headers,
        function (res) {
            console.log("uploaded: ", res);
            cb(res);
        }
    )
}
export function getCampaignEmailTemplateList(params, cb) {
    API.POST(PATHS.CAMPAIGN_EMAIL_LIST, params, {},
        function (res) {
            cb(res);
        }
    )
}


export function postUploadEmailTemplate(data, headers, cb) {
    API.GENERAL(PATHS.CAMPAIGN_EMAIL_ADD_TEMPLATE, 'POST', data, {}, headers,
        function (res) {
            console.log("uploaded:  ", res);
            cb(res);
        }
    )
}

export function getCampaignEmailDetail(id, cb) {
    API.GET(PATHS.CAMPAIGN_EMAIL_TEMPLATE_DETAIL(id), {}, {},
        function (res) {
            cb(res);
        }
    )
}

export function postCampaignEmailDeleteTemplate(id, cb) {
    API.POST(PATHS.CAMPAIGN_EMAIL_TEMPLATE_DELETE(id), {}, {},
        function (res) {
            cb(res);
        }
    )
}

export function postCampaignEmailDownloadFile(path, headers, cb) {
    API.DOWNLOADWITHHEAD(PATHS.CAMPAIGN_EMAIL_DETAIL_DOWNLOAD(path), headers, {}, {},
        function (res) {
            console.log("Downloading pltfm dispositions ", res);
            cb(res);
        }
    )

}

export function postCampaignHistory(params, cb) {
    API.POST(PATHS.CAMPAIGN_GET_CAMPAIGN_HISTORY, params, {},
        function (res) {
            cb(res);
        }
    )
}

export function postCampaignReschedule(params, cb) {
    API.POST(PATHS.CAMPAIGN_RESCHEDULE, params, {},
        function (res) {
            cb(res);
        }
    )
}
// credilio

export function getCredilioLeadDetail(mob, cb) {
    API.GET(PATHS.CREDILIO_DETAIL(mob), {}, {},
        function (res) {
            cb(res);
        }
    )
}

export function getListOfState(cb) {
    API.GET(PATHS.GET_STATE_LIST, {}, {},
        function (res) {
            cb(res);
        }
    )
}
// campaign reporting dashboard


export function postCampaignReportDashChannelStat(params, cb) {
    API.POST(PATHS.CAMPAIGN_REPORTING_DASHBOARD_CHANNEL_STATS, params, {},
        function (res) {
            cb(res);
        }
    )
}

// dashboard stats
export function postDashbaordApiAdminSummary(params, cb) {
    API.POST(PATHS.DASHBOARD_API_ADMIN_SUMMARY, params, {},
        function (res) {
            cb(res);
        }
    )
}

export function postDashbaordApiTeamLeadSummary(params, cb) {
    API.POST(PATHS.DASHBOARD_API_TEAMLEAD_SUMMARY, params, {},
        function (res) {
            cb(res);
        }
    )
}

export function postCampaignReportDashTrendLine(params, cb) {
    API.POST(PATHS.CAMPAIGN_REPORTING_DASHBOARD_TREADLINE, params, {},
        function (res) {
            cb(res);
        }
    )
}
