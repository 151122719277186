import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import { getLocalStorage } from "../utils/localStorage";
import { Navbar, Sidebar, UserProfile } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import lockImg from "./../data/lock.png";
import { useEffect } from "react";
import { HiMenuAlt1, HiOutlineChevronDoubleRight } from "react-icons/hi";
import { Fab } from "@mui/material";
import { AddIcCallOutlined } from "@mui/icons-material";
import { FaUserCircle } from "react-icons/fa";
const RestrictedPage = () => {
    const navigate = useNavigate();
    useEffect(() => {
        setTimeout(() => {
            navigate(-1)
        }, 2000);
    }, [])

    return (<>
        <div className="restrcited-page" >
            <div className="restrcited-page-inner">
                <div className="restrcited-page-img" >
                    <img src={lockImg} style={{ height: 125, width: 125 }} alt="restrictedpage" />
                </div>
                <p className="restrcited-page-p">You don't have permission to view this page</p>
            </div>
        </div>
    </>)
}

const RequireAuth = ({ allowedRoles }) => {
    const is_Authorized = getLocalStorage("USER_AUTH")
    const { role } = getLocalStorage("CURRENT_USER") ?? []
    const location = useLocation();
    const { activeMenu, setActiveMenu, setIsClicked, screenSize, setScreenSize, isClicked, handleClick } = useStateContext();

    useEffect(() => {
        const handleResize = () => setScreenSize(window.innerWidth);
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [screenSize]);
    useEffect(() => {
        if (screenSize <= 900) {
            setActiveMenu(false);
        } else { setActiveMenu(true) }
    }, [screenSize])


    return (
        is_Authorized
            ? <>
                <div style={{ background: "rgb(244, 249, 253)" }} className='flex relative '>
                    <div style={{ background: "#fff", zIndex: 1 }} className={` side-bar fixed ${activeMenu ? 'w-64 sidebar ' : screenSize <= 900 ? "w-0" : " w-14 sidebar bg-white"}`}>
                        <Sidebar />
                    </div>
                    <Fab size="small"
                        style={{
                            background: "rgb(31, 175, 229)",
                            color: "white",
                            position: "absolute",
                            zIndex: 3,
                            margin: 10,
                            zIndex: 9999,
                            display: (screenSize >= 900) ? "none" : (activeMenu ? "none" : "flex")
                        }}
                        aria-label="add"
                        onClick={() => {
                            setActiveMenu((prevActiveMenu) => !prevActiveMenu);
                            setIsClicked(false);
                            console.log(screenSize, activeMenu)
                        }}
                    >
                        <HiMenuAlt1 />
                    </Fab>
                    {
                        !activeMenu &&
                        <>
                            {/* {screenSize <= 900 ? <>
                                <Fab size="small"
                                    style={{
                                        background: "rgb(31, 175, 229)",
                                        color: "white",
                                        position: "absolute",
                                        zIndex: 3,
                                        margin: 10
                                    }}
                                    aria-label="add"
                                    onClick={() => {
                                        setActiveMenu((prevActiveMenu) => !prevActiveMenu);
                                        setIsClicked(false);
                                    }}
                                >
                                    <HiMenuAlt1 />
                                </Fab>
                            </> :
                                <div
                                    style={{
                                        position: "fixed",
                                        margin: 10,
                                        zIndex: 2,
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        gap: 10
                                    }}
                                >
                                    <button
                                        onClick={() => {
                                            setActiveMenu((prevActiveMenu) => !prevActiveMenu);
                                            setIsClicked(false);
                                        }}
                                        className="sidebar-open-btn"
                                    >
                                        <HiOutlineChevronDoubleRight />
                                    </button>
                                    <FaUserCircle onClick={() => handleClick('userProfile')} style={{ fontSize: 22, marginBottom: "-25px" }} />
                                    {isClicked.userProfile && (<UserProfile />)}
                                </div>
                            } */}

                            {/* <div
                                style={{
                                    position: "fixed",
                                    margin: 10,
                                    zIndex: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    gap: 10
                                }}
                            >
                                <button
                                    onClick={() => {
                                        setActiveMenu((prevActiveMenu) => !prevActiveMenu);
                                        setIsClicked(false);
                                    }}
                                    className="sidebar-open-btn"
                                >
                                    <HiOutlineChevronDoubleRight />
                                </button>
                                <FaUserCircle onClick={() => handleClick('userProfile')} style={{ fontSize: 22, marginBottom: "-25px" }} />
                                {isClicked.userProfile && (<UserProfile />)}
                            </div> */}

                        </>

                    }
                    {/* <Navbar/> */}
                    <div style={{ overflow: "hidden", background: "rgb(244, 249, 253)" }} className={`dark:bg-main-bg bg-main-bg min-h-screen w-full ${activeMenu ? " md:ml-64" : screenSize <= 900 ? "" : "md:ml-14"}`}>
                        {role && role.find(r => allowedRoles.includes(r))
                            ? <Outlet />
                            : <RestrictedPage />
                        }
                    </div>
                </div >
            </>
            : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;