import React,{useEffect, useState} from "react";
import { useLocation,useNavigate,useParams } from "react-router-dom";
import { getUserDetail } from "../../service/api";
import CustomAlert from "../CustomAlert";
import {FiEdit2} from "react-icons/fi"

export default function UserDetailPage(props){
    const { pathname} = useLocation();
    const navigate = useNavigate();
    const { id } = useParams()
    const [details,setDetails] = useState({
        "lmsUserId": "-",
        "phone": "-",
        "name": "-",
        "email": "-",
        "password": "-",
        "active": true,
        "ipBound": false,
        "company": "-",
        "department": "-",
        "designation": "-",
        "roles": [
            "-",
        ]
    })

    const [error, setError] = useState({
        hasError: false,
        errorMessage: "",
        errorType:"error"
      });
    useEffect(() => {
      getUserDetail(id,(res)=>{
        if(res && res.success){
            setDetails(res.response);
        }else{
           setError({
            hasError:true,
            errorMessage:"",
            errorType:"error"
           })
        }
      })
    },[setDetails])
    const { hasError,errorMessage,errorType } = error;
    const falsifyError = ()=>{
        setError({hasError:false})
    }
    const createDetail =(type)=>{
        const {phone,name,email,active,ipBound,company,department,designation,roles} = details;
        let tempDetails=[];
        switch(type){
            case "one":
                tempDetails=[
                    { 
                        key:"Basic Details",
                        values:[
                            // { key : "User Id" , value:lmsUserId },
                            { key : "Phone" , value:phone??"-" },
                            { key : "Name" , value:name??"-" },
                            { key : "Email" , value:email??"-" },
                        ]
                     },
                     { 
                        key:"Company Details",
                        values:[
                            { key : "Company" , value:company??"-" },
                            { key : "Department" , value:department??"-" },
                            { key : "Designation" , value:designation??"-" },
                        ]
                     },
                ]
                break;
            case "two":
                tempDetails=[
                    { 
                        key:"Security Details",
                        values:[
                            { key : "Active" , value:active?"YES":"NO" },
                            { key : "Ip Bound" , value:ipBound ?"YES":"NO"},
                            { key : "Permissions" , value:roles?roles.join(","):"-" },
                        ]
                     },
                ]
                break;
            default:
                break;
        }
        return tempDetails;
    }
    const toEditUser=()=>{
        navigate('/home/users/edituser', { state:{ userId:id } });
    }
    return(<>
    <div style={{marginTop:0,padding:10,height:"95vh",overflow:"scroll",background:"#fff"}}>
        <div style={styles.heading}>
            <span style={styles.headingTitle}>
                Details /
                <p 
                   onClick={() =>  navigator.clipboard.writeText(id)} 
                   style={{fontSize:12,cursor:"pointer"}}
                   title="copy user id"
                   className="CopyUserId"
                >
                    {id}
                </p>
             </span>
            <button onClick={toEditUser} className="UserEditBtn">
                <span style={{marginRight:10}}>Edit</span> <FiEdit2/>
            </button>
        </div>
        <hr/>
        <div style={styles.elementMainConatiner}>
            {/* <div style={styles.formContainer}>
               {createDetail().map((item)=>
                    <div key={item.key} className="CuElements"  >
                        <b>{item.key}</b>
                        <p>{item.value}</p>
                    </div>
               )}
            </div> */}
            <div className="grid-container">
                <div className="grid-item">
                    <div style={styles.elementMainConatiner}>
                    {createDetail("one").map((det)=>{
                        return(
                            <div key={det.key} >
                                <div style={styles.elementTitle}>
                                    <span>{det.key}</span>
                                </div>
                                <div style={styles.formContainer}>
                                {det.values.map((item)=>
                                    <div key={item.key} style={{overflow:"hidden"}} className="CuElements "  >
                                        <b style={{fontSize:15,margin:4}}>{item.key}</b>
                                        <p onClick={() =>  navigator.clipboard.writeText(item.value)} 
                                           style={{fontSize:14,margin:4,cursor:"pointer"}}
                                           title={`copy user ${item.key}`}
                                        >
                                           {item.value}
                                        </p>
                                    </div>
                                )}
                                </div>
                            </div>
                        )
                    })}
                    </div>
                </div>
                <div className="grid-item">
                    <div style={styles.elementMainConatiner}>
                        {createDetail("two").map((det)=>{
                            return(
                                <div key={det.key} >
                                    <div style={styles.elementTitle}>
                                        <span>{det.key}</span>
                                    </div>
                                    <div style={styles.formContainer}>
                                    {det.values.map((item)=>
                                        <div key={item.key} style={{overflow:"hidden"}} className="CuElementsTwo"  >
                                            <b style={{fontSize:15,margin:4}}>{item.key}</b>
                                            <p style={{fontSize:14,margin:4}}>{item.value}</p>
                                        </div>
                                    )}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
        <CustomAlert
            open={hasError}
            message={errorMessage}
            type={errorType}
            callback={falsifyError}
        />
    </div>
    </>)
}

const styles= {
    heading:{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding:"10px 0 10px 0px",
        borderRadius:10,
        fontFamily:"gb Regular",
        fontStyle:"none",
        fontWeight:"800"
    },
    headingTitle:{
        color:"#404040",
        fontSize:20,
    },
    headingDetail:{
        color:"rgb(149,149,149)",
        fontSize:13,
    },
    formContainer:{
        display:"flex",
        flexWrap:'wrap',
    },
    elementMainConatiner:{
        display: "flow-root",
        alignItems: "center",
        justifyContent: "start",
        padding:"10px 0 10px 10px",
        borderRadius:10,
        fontFamily:"gb Regular",
        fontStyle:"none",
        fontWeight:"800",
    },
    elementTitle:{
        fontSize: 18,
        fontStyle: "none",
        fontFamily: 'gb Regular',
        color:"darkolivegreen"
    },
    btnContainer:{
        padding:"10px 10px 10px 10px",
        display:"flex",
        justifyContent:'flex-start',
        alignContent:"center",
        alignItems:"center",
        margin:10,
    }
}