

export function makeSort(obj, screen) {

    const sort = obj;
    if (screen === "users") {
        if (obj.sort === "userId") {
            sort.sort = "userId"
        }
    }
    return sort;
}
export function findIdFieldDetail(obj) {
    const arr = Object.keys(obj);
    for (let i = 0; i < arr.length; i++) {
        let key = arr[i].toLowerCase();
        if (key.includes("id")) {
            return arr[i];
        }
    }
    return;
}
export function findFileIdFieldDetail(obj) {
    const arr = Object.keys(obj);
    for (let i = 0; i < arr.length; i++) {
        let key = arr[i].toLowerCase();
        if (key.includes("fileId")) {
            return arr[i];
        }
    }
    return;
}

export function convertFromCamelCase(str) {
    return str
        .replace(/(_|-)/g, ' ')
        .trim()
        .replace(/\w\S*/g, function (str) {
            return str.charAt(0).toUpperCase() + str.substr(1)
        })
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
}

export function debounce(func, timeout = 1800) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

export function makeFilters(obj, screen) {

    const filter = {};
    const search = {};

    Object.keys(obj).forEach(key => {
        let value = obj[key];
        if (screen === "USER_LISTING") {

            // User Filter
            if (key === "createdDate") {
                filter[key] = value;
            } else if (key === "company") {
                filter[key] = value.toLowerCase();
            }

        } else return {};
    })
    return { filter: filter, search: search };
}


export function convertTimestamptoDateWithTime(timestamp) {
    if (!timestamp) {
        return "-";
    }
    const dateObj = new Date(timestamp);
    const year = dateObj.getFullYear();
    let month = dateObj.getMonth() + 1;
    let date = dateObj.getDate();
    if (date < 10) date = "0" + date;
    if (month < 10) month = "0" + month;
    let totalDate = [year, month, date].join("-");
    let hrs = dateObj.getHours();
    let mints = dateObj.getMinutes();
    let seconds = dateObj.getSeconds();
    if (hrs < 10) hrs = `0${hrs}`;
    if (mints < 10) mints = `0${mints}`;
    if (seconds < 10) seconds = `0${seconds}`;
    let totalTime = hrs + ":" + mints + ":" + seconds;
    return [totalDate, totalTime].join("  ");

}
export function convertTimestamptoDay(timestamp) {
    if (!timestamp) {
        return "-";
    }
    const dateObj = new Date(timestamp);
    let day = dateObj.getDate();
    return day
}
export function convertTimestamptoMonth(timestamp) {
    if (!timestamp) {
        return "-";
    }
    const date = new Date(timestamp);
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    return monthNames[date.getMonth()];
}

export function convertTimestamptoYear(timestamp) {
    if (!timestamp) {
        return "-";
    }
    const dateObj = new Date(timestamp);
    let year = dateObj.getFullYear();
    return year;
}

export function formatAMPM(timestamp) {
    const date = new Date(timestamp);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export function convertTimestamptoDate(timestamp) {
    if (!timestamp) {
        return "-";
    }
    const dateObj = new Date(timestamp);
    const year = dateObj.getFullYear();
    let month = dateObj.getMonth() + 1;
    let date = dateObj.getDate();
    if (date < 10) date = "0" + date;
    if (month < 10) month = "0" + month;
    return [year, month, date].join("-");

}

export function convertToHumanize(array, type) {
    function humanize(str) {
        var i, frags = str.split('_');
        for (i = 0; i < frags.length; i++) {
            frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
    }
    const array2 = []
    array.forEach((item) => {
        if (type === "roles") {
            array2.push({ key: humanize(item), value: item })
        } if (type === "teamLeads") {
            array2.push({ key: humanize(item), value: item })
        } else if (type === "teamLeadsAssign") {
            array2.push({ key: humanize(item), value: item })
        } else if (type === "reportingTo") {
            array2.push({ key: item.split("@")[0], value: item })
        }
    })
    return array2;
}


export function convertFromCamelCaseToHumanize(data) {
    let resArr = [];
    for (const property in data) {
        let innerAnsArr = [];
        let curr = data[property]
        for (const item in curr) {
            if (item.match("createdDate") || item.match("lastUpdated")) {
                let obj = {
                    key: item, value: convertTimestamptoDate(curr[item]), label: convertFromCamelCase(item).toUpperCase()
                }
                innerAnsArr.push(obj);
            } else {
                let obj = {
                    key: item, value: curr[item], label: convertFromCamelCase(item).toUpperCase()
                }
                innerAnsArr.push(obj);
            }

        }
        let objOuter = {
            key: property, value: innerAnsArr, label: convertFromCamelCase(property).toUpperCase()
        }
        resArr.push(objOuter);

    }
    return resArr;
}


export function convertFromCamelCaseToHumanizeWithGroup(FormatedData, MainData) {
    let resArr = [];
    for (const property in FormatedData) {
        let innerAnsArr = [];
        let curr = FormatedData[property]
        for (const item in curr) {
            if (item.match("createdDate") || item.match("lastUpdated")) {
                let obj = {
                    key: item, value: convertTimestamptoDate(MainData[item]), label: convertFromCamelCase(item).toUpperCase()
                }
                innerAnsArr.push(obj);
            } else {
                if (MainData[item]) {
                    let obj = {
                        key: item, value: MainData[item], label: convertFromCamelCase(item).toUpperCase()
                    }
                    innerAnsArr.push(obj);
                }

            }

        }
        let objOuter = {
            key: property, value: innerAnsArr, label: convertFromCamelCase(property).toUpperCase()
        }
        resArr.push(objOuter);

    }
    return resArr;
}


export function convertObjectToArray(data) {
    let resArr = [];
    for (const item in data) {

        if (
            item.match("createdDate")
            || item.match("lastUpdated")
            || item.match("bureauFetchAt")
        ) {
            let obj = {
                key: item, value: convertTimestamptoDateWithTime(data[item]), label: convertFromCamelCase(item).toUpperCase()
            }
            resArr.push(obj);
        } else if (typeof data[item] === 'boolean') {
            let obj = {
                key: item, value: data[item] === true ? "YES" : "NO", label: convertFromCamelCase(item).toUpperCase()
            }
            resArr.push(obj);
        } else {
            let obj = {
                key: item, value: data[item] ?? "-", label: convertFromCamelCase(item).toUpperCase()
            }
            resArr.push(obj);
        }
    }
    return resArr;
}

export function convertStringArrayToKeyValueArray(data) {
    let resArr = [];
    for (let i = 0; i < data.length; i++) {
        let obj = {
            key: data[i], value: data[i]
        }
        resArr.push(obj);
    }
    return resArr;
}

export function convertBsdToNormalText(str) {
    str = str.split("_");
    let result = "";
    for (let i = 0; i < str.length; i++) {
        let curr = str[i].toLowerCase();
        result = result.concat(" ", curr);
    }
    return result;
}


export function convertTimestampToMonthDate(timestamp) {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    };

    const formattedDate = new Date(timestamp).toLocaleDateString('en-US', options);
    return formattedDate;
}

export function copyToClipboardFunc(str) {
    // Create a temporary input element
    const input = document.createElement('input');
    input.value = str;
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, 99999);
    document.execCommand('copy');
    document.body.removeChild(input);
};

export function getNameFromEmail(email) {
    const mail = email.split("@")[0];
    return mail ?? "-";
}

export function convertDateToTimestamp(date) {
    const dateObject = new Date(date);
    if (isNaN(dateObject.getTime())) {
        throw new Error('Invalid date');
    }
    return dateObject.getTime();
};