import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { BsFileTextFill, BsFillInfoCircleFill, BsListNested } from "react-icons/bs";
import "../style/sidebar.css"
import { ImUsers } from 'react-icons/im';
import { HiMenuAlt1, HiUserAdd } from 'react-icons/hi';
import { getLocalStorage } from '../utils/localStorage';
import { AiOutlineDatabase } from 'react-icons/ai';
import { FiUsers } from "react-icons/fi"
import { MdCampaign, MdOutlineDashboard, MdMarkChatUnread, MdDashboardCustomize } from "react-icons/md"
import { IoMdListBox } from 'react-icons/io';
import { RiFilePaperFill } from "react-icons/ri";
import logo from "../data/logo/tdfLogo.png";
import { FaUserCircle } from 'react-icons/fa';
import { TbDotsVertical } from 'react-icons/tb';
import UserProfile from './UserProfile';
import gsap from 'gsap';
const Sidebar = () => {
  const { activeMenu, setActiveMenu, setIsClicked, screenSize, isClicked, handleClick } = useStateContext();

  const { pathname } = useLocation();

  useEffect(() => {
    if (activeMenu) {
      gsap.to("#mytext", {
        duration: 0.5,
        opacity: 1,
        x: 0,
        stagger: 0.1,
        ease: 'power1.out',
      });
    } else {
      gsap.to("#mytext", {
        duration: 0.5,
        opacity: 0,
        x: -50,
        stagger: 0.1,
        ease: 'power1.in',
      });
    }
  }, [activeMenu]);

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
      setIsClicked(false);
    } else {
      setIsClicked(false);
    }
  };
  const currentUser = getLocalStorage("CURRENT_USER");
  const { name, email, designation } = currentUser;

  const CheckPermission = () => {
    const { role } = getLocalStorage("CURRENT_USER");
    const links = [
      {
        title: 'Admin',
        links: [
          { name: 'users', path: '/home/users', icon: <ImUsers />, allowed: ["ROLE_CRM_ADMIN"] },
          { name: 'create user', path: '/home/createuser', icon: <HiUserAdd />, allowed: ["ROLE_CRM_ADMIN"] }
        ],
        Permissions: ["ROLE_CRM_ADMIN"]
      },
      {
        title: 'Process Admin',
        links: [
          // { name: 'Dashboard', path: '/home/tdfAdminDashboard', icon: <MdOutlineDashboard />, allowed: ["ROLE_ADMIN", "ROLE_MANAGER"] },
          { name: 'Dashboard', path: '/home/adminDashboard', icon: <MdOutlineDashboard />, allowed: ["ROLE_ADMIN", "ROLE_MANAGER"] },
          { name: 'Client Allocation', path: '/home/processRecordAllocation/clientrecord', icon: <BsFileTextFill />, allowed: ["ROLE_ADMIN", "ROLE_MANAGER"] },
          { name: 'Platform Allocation', path: '/home/processRecordPlatformAllocation/tdfAdminAllocToTl', icon: <FiUsers />, allowed: ["ROLE_ADMIN", "ROLE_MANAGER"] },
          { name: 'Campaigns', path: '/home/adminCampaigns/records', icon: <MdCampaign />, allowed: ["ROLE_ADMIN", "ROLE_MANAGER"] },
          { name: 'Campaign Dashboard', path: '/home/campaignDashboard', icon: <MdDashboardCustomize />, allowed: ["ROLE_ADMIN", "ROLE_MANAGER"] },
          { name: 'Tele Performance', path: '/home/teleperformance/attempted', icon: <RiFilePaperFill />, allowed: ["ROLE_ADMIN", "ROLE_MANAGER"] },
        ],
        Permissions: ["ROLE_ADMIN", "ROLE_MANAGER"]
      },
      {
        title: 'Team Leads',
        links: [
          { name: 'Dashboard', path: '/home/teamLeadDashboard', icon: <MdOutlineDashboard />, allowed: ["ROLE_ADMIN", "ROLE_MANAGER", "ROLE_TEAM_LEAD"] },
          { name: 'Client Allocation', path: '/home/teamLeads', icon: <AiOutlineDatabase />, allowed: ["ROLE_ADMIN", "ROLE_MANAGER", "ROLE_TEAM_LEAD"] },
          { name: 'Platform Allocation', path: '/home/teamLead/teamLeadAllocToTele', icon: <BsListNested />, allowed: ["ROLE_ADMIN", "ROLE_MANAGER", "ROLE_TEAM_LEAD"] },
          // { name: 'Platform Metadata', path: '/home/teamLeadAllocFileIdToTele', icon: <FaListOl />, allowed: ["ROLE_ADMIN", "ROLE_MANAGER", "ROLE_TEAM_LEAD"] },
        ],
        Permissions: ["ROLE_MANAGER", "ROLE_TEAM_LEAD", "ROLE_TEAM_LEAD"]
      },
      {
        title: 'Tele Callers',
        links: [
          // { name: 'Your DashBoard', path: 'home/teleDashboard', icon: <MdOutlineDashboard />, allowed: [ "ROLE_TELE_CALLER"] },
          { name: 'Client Allocations', path: '/home/teleCallers', icon: <AiOutlineDatabase />, allowed: ["ROLE_TELE_CALLER"] },
          { name: 'Platform Allocation', path: '/home/teleTdfUsers', icon: <FiUsers />, allowed: ["ROLE_TELE_CALLER"] },
        ],
        Permissions: ["ROLE_TELE_CALLER"]
      },
      {
        title: 'Marketing',
        links: [
          { name: 'Post Blog', path: '/home/createBlog', icon: <BsFillInfoCircleFill />, allowed: ["ROLE_DM"] },
          { name: 'Blog List', path: '/home/blogList', icon: <IoMdListBox />, allowed: ["ROLE_DM"] },
        ],
        Permissions: ["ROLE_DM"]
      },
      {
        title: 'Campaigns',
        links: [
          { name: 'Campaigns', path: '/home/campaigns/records', icon: <MdCampaign />, allowed: ["ROLE_CRM_ADMIN", "ROLE_DS"] },
          { name: 'UTM', path: '/home/utm/records', icon: <MdMarkChatUnread />, allowed: ["ROLE_CRM_ADMIN", "ROLE_DS"] },

        ],
        Permissions: ["ROLE_DS"]
      },
    ]
    let list = [];
    for (let obj of links) {
      // Check if any role in the role array matches any permission in the Permissions array
      if (role.some(r => obj.Permissions.includes(r))) {
        let innerList = [];
        for (let key of obj.links) {
          // Check if any role in the role array is allowed for the link
          if (role.some(r => key.allowed.includes(r))) {
            innerList.push(key);
          }
        }
        let tempObj = obj;
        tempObj.links = innerList;
        list.push(tempObj);
      }
    }
    return list;
  }

  const checkActivePath = (screen) => {
    const str = pathname.split("/");
    const path = screen.split("/")
    if (
      str[0] === path[0] &&
      str[1] === path[1] &&
      str[2] === path[2]) {
      return true;
    } else {
      return false;
    }

  }

  return (
    <>
      <div style={{ marginTop: 0, marginLeft: activeMenu ? ".75rem" : "0px" }} className=" h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
        {/* <Navbar /> */}
        <div style={{
          display: "flex",
          justifyContent: "20px 0px 10px",
          alignItems: "center",
          gap: activeMenu ? 30 : 0,
          padding: "10px 0px",
          height: "10%",
          paddingRight: 20
        }}>

          <a to="/home" >
            <img style={{ height: 30, width: activeMenu ? 150 : 0, marginLeft: 5 }} src={logo} alt="logo" />
          </a>
          <div style={{ position: "relative", marginTop: 0, fontSize: 20, marginLeft: activeMenu ? 0 : 10, padding: 0 }}>
            <button onClick={() => {
              setActiveMenu((prevActiveMenu) => !prevActiveMenu);
              setIsClicked(false);
            }}><HiMenuAlt1></HiMenuAlt1></button>
          </div>
        </div>
        {/* {activeMenu && 
        ( */}
        <>
          <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", height: "90%" }} >
            <div style={{ marginTop: !activeMenu ? 40 : 5, }} >
              {CheckPermission().map((item) => (
                <div key={item.title}>
                  {
                    !activeMenu ? null : <>
                      <p id='mytext' style={{ color: "black", fontFamily: " 'Nunito Sans', sans-serif", fontSize: 16, fontWeight: "700" }} className="text-gray-400 dark:text-gray-400  mt-4 class-animation">
                        {item.title}
                      </p>
                    </>
                  }
                  <div style={{ borderLeft: "0px solid #efefef", marginLeft: 0 }}>
                    {item.links.map((link) => (
                      <NavLink
                        className={`${checkActivePath(link.path) ? "CustomLink-active" : "CustomLink-inactive"}`}
                        to={`${link.path}`}
                        key={link.name}
                        onClick={handleCloseSideBar}
                      >
                        {link.icon}
                        {
                          activeMenu ? <>
                            <span className="capitalize class-animation">{link.name}</span>
                          </> : null
                        }
                      </NavLink>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div style={{ justifyContent: activeMenu ? "space-between" : "center" }} className='sidebar-user-block'>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <FaUserCircle style={{ fontSize: 22 }} onClick={() => !activeMenu ? handleClick('userProfile') : null} />
                {
                  !activeMenu ? <></> :
                    <>
                      <span >
                        {name}
                      </span>
                    </>
                }
              </div>
              {
                !activeMenu ? <></> :
                  <>
                    <TbDotsVertical style={{ fontSize: 22 }}
                      onClick={() => handleClick('userProfile')} />
                  </>
              }

            </div>
          </div>
          {isClicked.userProfile && (<UserProfile />)}
        </>
        {/* )
        } */}
      </div>
    </>
  );
};

export default Sidebar;