import Cookies from "js-cookie";

export const SetCookie = (cookie_Name,USER_AUTH)=>{
  Cookies.set(cookie_Name,USER_AUTH,{
      expires:1,
      secure:true,
      sameSite:"strict",
      path:"/"
  })
}

export const GetCookie = (USER_AUTH)=>{
    return Cookies.get(USER_AUTH);
}

export const RemoveCookie = (USER_AUTH)=>{
    return Cookies.remove(USER_AUTH);
}
