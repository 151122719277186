import React from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { useStateContext } from '../contexts/ContextProvider';
import { clearLocalStorage, getLocalStorage } from '../utils/localStorage';
import {FaUserAlt} from "react-icons/fa";
import {AiOutlineLogout} from "react-icons/ai"
import { RemoveCookie } from '../utils/cookies';
const UserProfile = (props) => {
  const {setIsClicked} = useStateContext();
  const currentUser = getLocalStorage("CURRENT_USER");
  const { name,email,designation } = currentUser;
  const closeProfile = ()=>{
    setIsClicked(false);
  }
  const handleLogout=()=>{
    RemoveCookie("USER_AUTH");
    clearLocalStorage();
    window.location.reload();
  }

  return(<>
  <div  className="profile-main-container">
    <div className='profile-inner-top'>
      <button
        type="button"
        onClick={closeProfile}
        style={{ color: "#1fafe5ff" }}
        className="text-2xl rounded-full  hover:bg-light-gray mt-0 block "
      >
        <MdOutlineCancel />
      </button>
    </div>
    <div className='profile-mid-container'>
    {/* <FaUserAlt style={{height:25,width:25}} className="rounded-full"/> */}
      <div>
        <p className="p-p1"> {name} </p>
        <p className="p-p2">  {designation}   </p>
        <p className="p-p3"> {email} </p>
      </div>
    </div>
    <div className='profile-bottom-container'>
        <button 
            onClick={handleLogout}
            className='profile-logout-btn'
            >
            <AiOutlineLogout/>
            <div>Logout</div>
        </button>
        
    </div>
  </div>
  </>)
};

export default UserProfile;