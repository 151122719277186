import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import "./App.css";

// import { Ecommerce, Login, NotFound } from './pages';
import MainLayout from './Layout/MainLayout';
import Unauthorized from './pages/Unauthorized';
import RequireAuth from './routes/RequiredAuth';
import UserDetailPage from './components/detail/UserDetailPage';
import CreateUserScreen from './pages/admin/CreateUser';

//fonts
import "./assets/fonts/NotoSansKR-Black.otf";
import "./assets/fonts/NotoSansKR-Bold.otf";
import "./assets/fonts/NotoSansKR-Light.otf";
import "./assets/fonts/NotoSansKR-Medium.otf";
import "./assets/fonts/NotoSansKR-Regular.otf";
import "./assets/fonts/NotoSansKR-Thin.otf";
import EditUserScreen from './pages/admin/EditUser';
import ProccessAdminClientMainScreen from "./pages/ProcessAdminRecordAllocation/ProcessAdminClientMain";
import ProccessAdminPlatformMainScreen from "./pages/ProcessAdminRecordAllocation/ProcessAdminPlatformMain";

const Login = lazy(() => import("./pages/Login"))
const NotFound = lazy(() => import("./pages/NotFound"))
const UserList = lazy(() => import("./pages/admin/UserList"))
const ExcelFileAllocationScreen = lazy(() => import("./pages/fileAllocation/ExcelFileUpload"))
const ProcessAdminRecordAllocationScreen = lazy(() => import("./pages/ProcessAdminRecordAllocation/ProcessAdminRecordAllocation"))
const ProcessAllocationDetailScreen = lazy(() => import("./pages/ProcessAdminRecordAllocation/ProcessAllocationDetail"));
const AllRecordViewScreen = lazy(() => import("./pages/ProcessAdminRecordAllocation/AllViewScreen"));
const TeamLeadsToTeleAllocationScreen = lazy(() => import("./pages/TeamLeads/TeamLeadsToTeleCallers"))
const TeleCallerScreen = lazy(() => import("./pages/AllViewTeleCaller/TeleCallerScreen"))
const TeleCallerCustomerView = lazy(() => import("./pages/AllViewTeleCaller/TeleCallerCustomerView"))

// const AdminTdfUsersScreen = lazy(() => import("./pages/ProcessAdminRecordAllocation/AdminTdfUsers"));
const TeleCallersDashboard = lazy(() => import("./pages/AllViewTeleCaller/TeleCallerDashboard"));

const TdfUserDetailScreen = lazy(() => import("./components/detail/TdfUserDetailScreen"))
const TeleCallerTdfUsersScreen = lazy(() => import("./pages/AllViewTeleCaller/TeleCallerTdfUsers"))
const CampaignScreen = lazy(() => import("./pages/campaign/CampaignScreen"))
const UtmScreen = lazy(() => import("./pages/campaign/UtmScreen"))


const CreateBlogScreen = lazy(() => import("./pages/BlogPost/CreateBlogScreen"))
const BlogListingScreen = lazy(() => import("./pages/BlogPost/BlogListing"))
const BlogDetailPage = lazy(() => import("./pages/BlogPost/BlogDetailPage"));

const TdfUserAllocationToTcScreen = lazy(() => import("./pages/TeamLeads/TdfUserAllocationToTc"));
const TdfUserFileIdAllocationScreen = lazy(() => import("./pages/TeamLeads/TdfUserFileIdAllocation"));

const TdfUserAllocToTcDetailScreen = lazy(() => import("./pages/TeamLeads/TdfUserAllocToTcDetail"));

const TdfUserDetailViewTlScreen = lazy(() => import("./pages/TeamLeads/TdfUserDetailView"));
const TeamLeadDashboardScreen = lazy(() => import("./pages/TeamLeads/TeamLeadDashboard"));

const TdfProcessAdminAllocationToTlScreen = lazy(() => import("./pages/ProcessAdminRecordAllocation/ProcessAdminPlatformAllocation"));
const ProcessAdminTdfAllocFileIdToTlScreen = lazy(() => import("./pages/ProcessAdminRecordAllocation/ProcessAdminTdfAllocFileIdToTl"));
const ProcessAdminTdfAllocToTlDetailScreen = lazy(() => import("./pages/ProcessAdminRecordAllocation/ProcessAdminPlatformAllocToTlDetail"));
const ProcessAdminDashboardScreen = lazy(() => import("./pages/ProcessAdminRecordAllocation/ProcessAdminDashboard"));
const EditBlogScreen = lazy(() => import("./pages/BlogPost/EditBlogScreen"));

const ProcessAdminPlatformUserDetailScreen = lazy(() => import("./pages/ProcessAdminRecordAllocation/ProcessAdminPlatformUserDetailScreen"))
const ProcessAdminClientCustomerViewScreen = lazy(() => import("./pages/ProcessAdminRecordAllocation/ProcessAdminClientCustomerView"));

const CampaignMainScreen = lazy(() => import("./pages/campaign/CampaignMainScreen"));
const UtmMainScreen = lazy(() => import("./pages/campaign/UtmMainScreen"));

const CreateCampaignScreen = lazy(() => import("./pages/campaign/CreateCampaignScreen"));
const CreateUtmScreen = lazy(() => import("./pages/campaign/CreateUtmScreen"));



const ProcessAdminCampaignMainScreen = lazy(() => import("./pages/campaign/ProcessAdminCampaignMain"));
const ProcessAdminCampaignApproval = lazy(() => import("./pages/campaign/ProcessAdminCampaignApproval"));
const CampaignDetailsScreen = lazy(() => import("./pages/campaign/CampaignDetail"));


// tele performance
const TelePerformanceNavigationScreen = lazy(() => import("./pages/TeleDashboard/TelePerformanceNav"));
const TeleAttempedScreen = lazy(() => import("./pages/TeleDashboard/TeleAttempedScreen"));
const TeleConnectedScreen = lazy(() => import("./pages/TeleDashboard/TeleConnectedScreen"));

const TeamLeadPlatformMainScreen = lazy(() => import("./pages/TeamLeads/TeamLeadPlatformMain"));

const TemplateMainScreen = lazy(() => import("./pages/campaign/TemplateMainScreen"));

// new dashboard
const AdminDashboard = lazy(() => import("./components/Dashboard/AdminDashboard"));
// campaign reporting dashboard
const CampaignReportingDashboard = lazy(() => import("./pages/campaign/CampaignReportingDashboard"));

function LoaderScreen() {
    return (
        <>
            <div className="loading">Loading&#8230;</div>
        </>
    )
}

const App = (props) => {
    return (
        <>
            <Suspense fallback={<LoaderScreen />}>

                <Routes>
                    <Route path='/' element={<MainLayout />}>
                        {/* Public routes */}
                        <Route path="/" element={<Login />} />
                        <Route path="/login" element={<Login />} />
                        <Route exact path="/unauthorized" element={<Unauthorized />} />
                        <Route path="*" element={<NotFound />} />
                        {/* Protected routes */}

                        <Route path='/home' element={<RequireAuth allowedRoles={["ROLE_CRM_ADMIN"]} />}>
                            <Route path="/home/users" element={<UserList />} />
                            <Route path="/home/createuser" element={<CreateUserScreen />} />
                            <Route path="/home/users/edituser" element={<EditUserScreen />} />
                            <Route path="/home/users/:id" element={<UserDetailPage />} />
                            {/* <Route path="*" element={<NotFound/>} /> */}
                        </Route>
                        <Route path='/home' element={<RequireAuth allowedRoles={["ROLE_CRM_ADMIN", "ROLE_ADMIN", "ROLE_MANAGER"]} />}>
                            <Route path="/home/processRecordAllocation" element={<ProccessAdminClientMainScreen />}>
                                <Route index path="/home/processRecordAllocation/clientrecord" element={<ProcessAdminRecordAllocationScreen />} />
                                <Route path="/home/processRecordAllocation/fileupload" element={<ExcelFileAllocationScreen />} />
                                <Route path="/home/processRecordAllocation/records" element={<AllRecordViewScreen />} />
                                <Route path="/home/processRecordAllocation/records/:id" element={<ProcessAdminClientCustomerViewScreen />} />
                                <Route path="/home/processRecordAllocation/clientrecord/:id" element={<ProcessAllocationDetailScreen />} />
                                <Route path="/home/processRecordAllocation/clientrecord/details/:id" element={<ProcessAdminClientCustomerViewScreen />} />
                            </Route>
                            <Route path="/home/processRecordPlatformAllocation" element={<ProccessAdminPlatformMainScreen />}>
                                <Route index path="/home/processRecordPlatformAllocation/tdfAdminAllocToTl" element={<TdfProcessAdminAllocationToTlScreen />} />
                                <Route path="/home/processRecordPlatformAllocation/tdfAdminAllocToTl/:id" element={<ProcessAdminPlatformUserDetailScreen />} />
                                <Route path="/home/processRecordPlatformAllocation/tdfAdminAllocFileIdToTl" element={< ProcessAdminTdfAllocFileIdToTlScreen />} />
                                <Route path="/home/processRecordPlatformAllocation/tdfAdminAllocFileIdToTl/:id" element={<ProcessAdminTdfAllocToTlDetailScreen />} />
                                <Route path="/home/processRecordPlatformAllocation/tdfAdminAllocFileIdToTl/detail/:id" element={<ProcessAdminPlatformUserDetailScreen />} />
                            </Route>
                            <Route path="/home/adminCampaigns" element={<ProcessAdminCampaignMainScreen />}>
                                <Route index path="/home/adminCampaigns/records" element={<ProcessAdminCampaignApproval />} />
                                <Route path="/home/adminCampaigns/records/:id" element={<CampaignDetailsScreen />} />
                                <Route path='/home/adminCampaigns/templates' element={<TemplateMainScreen />} />

                            </Route>
                            <Route path="/home/tdfAdminDashboard" element={< ProcessAdminDashboardScreen />} />
                            <Route path="/home/adminDashboard" element={<AdminDashboard />} />
                            <Route path="/home/campaignDashboard" element={<CampaignReportingDashboard />} />
                            <Route path="/home/teleperformance" element={<TelePerformanceNavigationScreen />}>
                                <Route index path="/home/teleperformance/attempted" element={<TeleAttempedScreen />} />
                                <Route path="/home/teleperformance/connected" element={<TeleConnectedScreen />} />
                            </Route>
                        </Route>
                        <Route path='/home' element={<RequireAuth allowedRoles={["ROLE_CRM_ADMIN", "ROLE_ADMIN", "ROLE_MANAGER", "ROLE_TEAM_LEAD"]} />}>
                            <Route path="/home/teamLead/" element={<TeamLeadPlatformMainScreen />}>
                                <Route index path="/home/teamLead/teamLeadAllocToTele" element={<TdfUserAllocationToTcScreen />} />
                                <Route path="/home/teamLead/teamLeadAllocToTele/:id" element={<ProcessAdminPlatformUserDetailScreen />} />
                                <Route path="/home/teamLead/teamLeadAllocFileIdToTele" element={<TdfUserFileIdAllocationScreen />} />
                                <Route path="/home/teamLead/teamLeadAllocFileIdToTele/:id" element={<TdfUserAllocToTcDetailScreen />} />
                                <Route path="/home/teamLead/teamLeadAllocFileIdToTele/detail/:id" element={<ProcessAdminPlatformUserDetailScreen />} />
                            </Route>
                            <Route path="/home/teamLeads" element={<TeamLeadsToTeleAllocationScreen />} />
                            <Route path="/home/teamLeads/:id" element={<TeleCallerCustomerView />} />
                            <Route path="/home/teamLeadDashboard" element={<TeamLeadDashboardScreen />} />
                        </Route>
                        <Route path='/home' element={<RequireAuth allowedRoles={["ROLE_CRM_ADMIN", "ROLE_ADMIN", "ROLE_MANAGER", "ROLE_TEAM_LEAD", "ROLE_TELE_CALLER"]} />}>
                            <Route path="*" element={<NotFound />} />
                        </Route>
                        <Route path='/home' element={<RequireAuth allowedRoles={["ROLE_CRM_ADMIN", "ROLE_TELE_CALLER"]} />}>
                            <Route path="/home/teleCallers" element={<TeleCallerScreen />} />

                            <Route path='/home/teleCallers/customerView/:id' element={<TeleCallerCustomerView />} />
                            <Route path='/home/teleDashboard' element={<TeleCallersDashboard />} />
                            <Route path="/home/teleTdfUsers" element={<TeleCallerTdfUsersScreen />} />
                            <Route path="/home/teleTdfUsers/:id" element={<TdfUserDetailScreen />} />
                        </Route>
                        <Route path='/home' element={<RequireAuth allowedRoles={["ROLE_CRM_ADMIN", "ROLE_DM"]} />}>
                            <Route path="/home/createBlog" element={<CreateBlogScreen />} />
                            <Route path="/home/blogList" element={<BlogListingScreen />} />
                            <Route path="/home/blogList/:id" element={<BlogDetailPage />} />
                            <Route path="/home/blogList/:id/edit" element={<EditBlogScreen />} />
                        </Route>


                        <Route path='/home' element={<RequireAuth allowedRoles={["ROLE_CRM_ADMIN", "ROLE_DS"]} />}>
                            <Route path='/home/campaigns/' element={<CampaignMainScreen />}>
                                <Route index path="/home/campaigns/records/" element={<CampaignScreen />} />
                                <Route index path="/home/campaigns/records/:id" element={<CampaignDetailsScreen />} />
                                <Route path="/home/campaigns/createCampaign/" element={<CreateCampaignScreen />} />
                            </Route>
                            <Route path='/home/utm/' element={<UtmMainScreen />}>
                                <Route index path="/home/utm/records" element={<UtmScreen />} />
                                <Route path="/home/utm/createCampaign/" element={<CreateUtmScreen />} />
                            </Route>

                        </Route>

                        {/* catch All */}
                    </Route>
                </Routes>
            </Suspense>
        </>
    )
}

export default App;