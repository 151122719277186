
import React from 'react';
import "./style.css";
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { getLocalStorage } from '../../utils/localStorage';
import {  FaListOl } from 'react-icons/fa';
import { BsListNested } from 'react-icons/bs';


function ProccessAdminPlatformMainScreen(props) {
    const { pathname } = useLocation();


    const CheckPermission = () => {
        const { role } = getLocalStorage("CURRENT_USER");
        const links = [

            {
                title: 'Process Admin',
                links: [
                    // { name: '', path: '/home/processRecordPlatformAllocation/tdfUsers', icon: <FiUsers />, allowed: ["ROLE_ADMIN", "ROLE_MANAGER"] },
                    { name: 'All Records', path: '/home/processRecordPlatformAllocation/tdfAdminAllocToTl', icon: <BsListNested />, allowed: ["ROLE_ADMIN", "ROLE_MANAGER"] },
                    { name: 'Metadata', path: '/home/processRecordPlatformAllocation/tdfAdminAllocFileIdToTl', icon: <FaListOl />, allowed: ["ROLE_ADMIN", "ROLE_MANAGER"] },
                ],
                Permissions: ["ROLE_ADMIN", "ROLE_MANAGER"]
            }

        ]

        let list = [];
        for (let obj of links) {
            // Check if any role in the role array matches any permission in the Permissions array
            if (role.some(r => obj.Permissions.includes(r))) {
                let innerList = [];
                for (let key of obj.links) {
                    // Check if any role in the role array is allowed for the link
                    if (role.some(r => key.allowed.includes(r))) {
                        innerList.push(key);
                    }
                }
                let tempObj = obj;
                tempObj.links = innerList;
                list.push(tempObj);
            }
        }
        return list;
    }

    const checkActivePath = (screen) => {
        const str = pathname.split("/");
        const path = screen.split("/")
        if (
            str[0] === path[0] &&
            str[1] === path[1] &&
            str[2] === path[2] &&
            str[3] === path[3]) {
            return true;
        } else {
            return false;
        }

    }


    return (
        <div style={{ marginTop: 0, padding: 10, overflow: "hidden", fontFamily: "gb Regular" }}>

            {CheckPermission().map((item) => (

                <div key={item.title} className='pa-top-bar'>
                    {item.links.map((link) => (
                        <NavLink
                            className="pa-CustomLink"
                            to={`${link.path}`}
                            key={link.name}
                            style={
                                checkActivePath(link.path) ?
                                    {
                                        backgroundColor: "#1fafe5ff",
                                        color: "#fff",
                                        fontWeight: 800,
                                    } : {
                                        backgroundColor: '#fff',
                                        color: 'rgb(96 82 82',
                                        fontWeight: 800,
                                    }
                            }

                        >
                            {link.icon}
                            <span className="capitalize ">{link.name}</span>
                        </NavLink>
                    ))}
                </div>

            ))}
            <div className='pa-main-outlet'>
                <Outlet ></Outlet>
            </div>

        </div>
    )
}


export default ProccessAdminPlatformMainScreen;