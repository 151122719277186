import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from "./App"
import "./index.css";
import { ContextProvider } from "./contexts/ContextProvider";
import { AuthProvider } from "./contexts/AuthContext";

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <AuthProvider>
        <ContextProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/*" element={<App />} />
                </Routes>
            </BrowserRouter>
        </ContextProvider>
    </AuthProvider>
);
//<React.StrictMode> remove
