import { Checkbox, ListItemText, MenuItem,  TextField, Typography } from "@mui/material";
import React from "react";
import CustomAlert from "../../components/CustomAlert";
import { withNavigation } from "../../routes/NavigatedRoute";
import { addAdminUser, getLMSReportingToListing, getLMSRoleListing } from "../../service/api";
import { CUSTOM_REGEX } from "../../utils/regex";
import {convertToHumanize} from "../../utils/helper"




class CreateUserScreen extends React.Component{
    constructor(props){
        super(props);
        this.state={
            name:{ value:"", error:""},
            email:{ value:"", error:""},
            password:{ value:"", error:""},
            phone:{ value:"", error:""},
            active:true,
            ipBound:true,
            company:"DueFactory",
            department:"Collection",
            designation:"teleCaller",
            roles:["ROLE_TELE_CALLER"],
            reportingTo:"crmadmin@theduefactory.com",
            errors: {},
            hasError:false,
            errorMessage:"",
            errorType:"",
            crmRoles:[],
            crmReportingTo:[]
        }
    }

    componentDidMount(){
        this.getRoleListing();
        this.getRoleReportingToListing();
    }
    getRoleListing = ()=>{
        getLMSRoleListing((res)=>{
            if(res){
                this.setState({
                    crmRoles:res
                })
            }else{
                this.setState({
                    hasError:true,
                    errorMessage:"error in getting lms roles",
                    errorType:"error"
                })
            }
        })
    }
    getRoleReportingToListing = ()=>{
        getLMSReportingToListing((res)=>{
            if(res){
                this.setState({
                    crmReportingTo:res
                })
            }else{
                this.setState({
                    hasError:true,
                    errorMessage:"error in getting lms reporting roles list",
                    errorType:"error"
                })
            }
        })
    }

    loadOptions = opts => {
        return opts.map(item => {
            return <MenuItem key={item.key} value={item.value}>
                {item.key}</MenuItem>
        })
    }
    validate = (event)=>{
        const {name,value} = event.target;
        const { name_regex,email_regex,phone_regex,password_regex} = CUSTOM_REGEX;
        if(name==="name"){
            if (!value) {
                this.setState((state)=> {
                    return { 
                        [name]: Object.assign({}, 
                        state[name], {
                            error:'Cannot be empty'
                      })
                    }
                  })
            } else if (!name_regex.test(value)) {
                this.setState((state)=> {
                    return { 
                        [name]: Object.assign({}, 
                        state[name], {
                            error:'Only letters'
                      })
                    }
                  })
            }else{
                this.setState((state)=> {
                    return { 
                        [name]: Object.assign({}, 
                        state[name], {
                            error:''
                      })
                    }
                  })
            }
        }
        if(name==="email"){
            if (!value) {
                this.setState((state)=> {
                    return { 
                        [name]: Object.assign({}, 
                        state[name], {
                            error:'Cannot be empty'
                      })
                    }
                  })
            } else if (!email_regex.test(value)) {
                this.setState((state)=> {
                    return { 
                        [name]: Object.assign({}, 
                        state[name], {
                            error:'Please enter valid email'
                      })
                    }
                  })
            }else{
                this.setState((state)=> {
                    return { 
                        [name]: Object.assign({}, 
                        state[name], {
                            error:''
                      })
                    }
                  })
            }
        }
        if(name==="password"){
            if (!value) {
                this.setState((state)=> {
                    return { 
                        [name]: Object.assign({}, 
                        state[name], {
                            error:'Cannot be empty'
                      })
                    }
                  })
            } else if (!password_regex.test(value)) {
                this.setState((state)=> {
                    return { 
                        [name]: Object.assign({}, 
                        state[name], {
                            error:'Minimum eight characters, at least one letter, one number and one special character'
                      })
                    }
                  })
            }else{
                this.setState((state)=> {
                    return { 
                        [name]: Object.assign({}, 
                        state[name], {
                            error:''
                      })
                    }
                  })
            }
        }
        if(name==="phone"){
            if (!value) {
                this.setState((state)=> {
                    return { 
                        [name]: Object.assign({}, 
                        state[name], {
                            error:'Cannot be empty'
                      })
                    }
                  })
            } else if (!phone_regex.test(value)) {
                this.setState((state)=> {
                    return { 
                        [name]: Object.assign({}, 
                        state[name], {
                            error:'Please enter valid phone number'
                      })
                    }
                  })
            }else{
                this.setState((state)=> {
                    return { 
                        [name]: Object.assign({}, 
                        state[name], {
                            error:''
                      })
                    }
                  })
            }
        }
    }
   
   

    createUserValue = () =>{
        const {name,email,password,phone,active,ipBound,company,department,designation,roles,crmRoles,reportingTo,crmReportingTo} = this.state;
        let createValue =[
            {
                blockTitle:"Basic Details",
                values:[
                    {
                        id:"name",type:"text",name:"name",label:"Name",isRequired:true,isDisabled:false,isSelect:false,
                        value:name.value,multiline:false,error:name.error,
                        option:[
                            { key:"",value:" "}
                        ]
                    },
                    {
                        id:"email",type:"text",name:"email",label:"Email",isRequired:true,isDisabled:false,isSelect:false,
                        value:email.value,multiline:false,error:email.error,
                        option:[
                            { key:"",value:" "}
                        ]
                    },
                    {
                        id:"password",type:"password",name:"password",label:"Password",isRequired:true,isDisabled:false,isSelect:false,
                        value:password.value,multiline:false,error:password.error,
                        option:[
                            { key:"",value:" "}
                        ]
                    },
                    {
                        id:"phone",type:"number",name:"phone",label:"Phone",isRequired:true,isDisabled:false,isSelect:false,
                        value:phone.value,multiline:false,max:10,error:phone.error,
                        option:[
                            { key:"",value:" "}
                        ]
                    },
                ]
            },
            {
                blockTitle:"Company Basic Detail",
                values:[
                    {
                        id:"company",type:"select",name:"company",label:"Company",isRequired:true,isDisabled:false,isSelect:true,
                        value:company,multiline:false,error:"",
                        option:[
                            { key:"DUE FACTORY",value:"DueFactory"},
                        ]
                    },
                    {
                        id:"department",type:"select",name:"department",label:"Department",isRequired:true,isDisabled:false,isSelect:true,
                        value:department,multiline:false,error:"",
                        option:[
                            { key:"Collection",value:"Collection"},
                            { key:"Operations",value:"Operations"},
                            { key:"Tech",value:"Tech"},
                            { key:"Marketing",value:"Marketing"},
                            { key:"DataScience",value:"DataScience"},
                        ]
                    },
                    {
                        id:"designation",type:"select",name:"designation",label:"Designation",isRequired:true,isDisabled:false,isSelect:true,
                        value:designation,multiline:false,error:"",
                        option:[
                            { key:"Manager",value:"Manager"},
                            { key:"Team Leader",value:"Team Leader"},
                            { key:"Admin",value:"Admin"},
                            { key:"Customer Service Executive",value:"Customer Service Executive"},
                            { key:"Others",value:"Others"},
                        ]
                    },
                ]
            },
            {
                blockTitle:"Authorize Detail",
                values:[
                    {
                        id:"active",type:"select",name:"active",label:"Active",isRequired:true,isDisabled:false,isSelect:true,
                        value:active,multiline:false,error:"",
                        option:[
                            { key:"YES",value:true},
                            { key:"NO",value:false},
                        ]
                    },
                    {
                        id:"ipBound",type:"select",name:"ipBound",label:"Ip Bound",isRequired:true,isDisabled:false,isSelect:true,
                        value:ipBound,multiline:false,error:"",
                        option:[
                            { key:"YES",value:true},
                            { key:"NO",value:false},
                        ]
                    },
                    {
                        id:"roles",type:"select",name:"roles",label:"Authorized Roles",isRequired:true,isDisabled:false,isSelect:true,
                        value:roles,multiline:false,multiple:true,error:"",
                        option:convertToHumanize(crmRoles,"roles")
                    },
                    {
                        id:"reportingTo",type:"select",name:"reportingTo",label:"Reporting To",isRequired:true,isDisabled:false,isSelect:true,
                        value:reportingTo,multiline:false,error:"",
                        option:convertToHumanize(crmReportingTo,"reportingTo")
                    },
                ]
            }
        ]
        return createValue;
    }
    clearDetail=()=>{
        this.setState({
            name:{...this.state.name,value:""},
            email:{...this.state.email,value:""},
            password:{...this.state.password,value:""},
            phone:{...this.state.phone,value:""},
            active:true,
            ipBound:true,
            company:"tdf",
            department:"collection",
            designation:"agent",
            roles:["ROLE_DEMO"],
            reportingTo:"",
        })
    }
    handleField = (event) => {
        const { name, value } = event.target;
        if(name==="name" || name==="email" || name==="password" || name==="phone"){
            this.setState((state)=> {
                return { 
                    [name]: Object.assign({}, 
                    state[name], {
                        value:value
                  })
                }
              })
        }else{
            this.setState({
                [name]:value
            })
        } 
    }

    
    handleValidation = ()=>{
        let formIsValid= true;
        const { name,email,password,phone}= this.state;
        const {phone_regex,name_regex,email_regex,password_regex} = CUSTOM_REGEX;
        if(name.error || !name.value){
            formIsValid=false;
            this.setState((state)=> {
                return { 
                    name: Object.assign({}, 
                    state.name, {
                        error:'Cannot be empty'
                  })
                }
              })
        }
        if(name.error && !name_regex.test(name.value)){
            formIsValid=false;
            this.setState((state)=> {
                return { 
                    name: Object.assign({}, 
                    state.name, {
                        error:'Please enter valid name'
                  })
                }
              })
        }
        if(email.error || !email.value){
            formIsValid=false;
            this.setState((state)=> {
                return { 
                    email: Object.assign({}, 
                    state.email, {
                        error:'Cannot be empty'
                  })
                }
              })
        }
        if(email.error && !email_regex.test(email.value)){
            formIsValid=false;
            this.setState((state)=> {
                return { 
                    email: Object.assign({}, 
                    state.email, {
                        error:'Please enter valid email'
                  })
                }
              })
        }
        if(password.error|| !password.value){
            formIsValid=false;
            this.setState((state)=> {
                return { 
                    password: Object.assign({}, 
                    state.password, {
                        error:'Cannot be empty'
                  })
                }
              })
        }
        if(password.error&& password.value && !password_regex.test(password.value)){
            formIsValid=false;
            this.setState((state)=> {
                return { 
                    password: Object.assign({}, 
                    state.password, {
                        error:'Minimum eight characters, at least one letter, one number and one special character'
                  })
                }
              })
        }
        if(phone.error|| !phone.value){
            formIsValid=false;
            this.setState((state)=> {
                return { 
                    phone: Object.assign({}, 
                    state.phone, {
                        error:'Cannot be empty'
                  })
                }
            })
        }
        if(phone.error && !phone_regex.test(phone.value)){
            formIsValid=false;
            this.setState((state)=> {
                return { 
                    phone: Object.assign({}, 
                    state.phone, {
                        error:'Please enter valid phone number'
                  })
                }
            })
        }
        return formIsValid;
    }

    addUser = ()=>{
        const {phone,name,email,password,active,ipBound,company,department,designation,roles,reportingTo} = this.state;
        let params={
            "phone": phone.value,
            "name": name.value,
            "email": email.value,
            "password": password.value,
            "active":active,
            "ipBound":ipBound,
            "company": company,
            "department": department,
            "designation": designation,
            "roles": roles,
            "reportingToEmail":reportingTo
        }
        if(this.handleValidation()){
            addAdminUser(params,(res)=>{
                if(res && res.success){
                    this.setState({
                        hasError:true,
                        errorType:"success",
                        errorMessage:res&& res.message? res.message:"succesfully create"
                    })
                    this.clearDetail()
                }else{
                    this.setState({
                        hasError:true,
                        errorType:"error",
                        errorMessage:res&& res.message? res.message:"",
                    })
                }
            })
            
        }else{
            this.setState({
                hasError:true,
                errorType:"error",
                errorMessage:"fill form properly"
            })
        }

    }
    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }
    handleChange = (event) => {
        const { value } = event.target;
        console.log(value);
        this.setState({
            roles: typeof value === 'string' ? value.split(',') : value,
        })
    };
    render(){

        const {hasError,errorMessage,errorType,roles}= this.state;
        return(
            <>
            <div style={{marginTop:0,padding:10,height:"90vh",overflow:"scroll"}}>
                 <div style={styles.headingTop}>
                    <span style={styles.headingTitle}>
                        Create User Form /
                        <p 
                        style={{fontSize:12,cursor:"pointer", color: '#40404073'}}
                        title="copy user id"
                        className="CopyUserId"
                        >
                        Create user for the Due Factory customer relationship managing tool ( crm )
                        </p>
                    </span>
                </div>
                <hr style={{marginBottom:10}}/>
                 <div style={styles.elementMainConatiner}>
                    {this.createUserValue().map((ite,key)=>
                       <div key={key}>
                        <div style={styles.elementTitle}>
                        <span>{ite.blockTitle}</span>
                        </div>
                        <div style={styles.formContainer}>
                        {ite.values.map((item,i)=>
                            <div className="CuElements" key={item.id} >
                                <Typography style={{fontSize:12}}>
                                    {item.label}
                                </Typography>
                                <TextField
                                    // className="CustomTextField"
                                    size={'small'}
                                    id={item.id}
                                    type={item.type}
                                    name={item.name}
                                    required={item.isRequired}
                                    disabled={item.isDisabled}
                                    select={item.isSelect}
                                    value={item.value}
                                    onChange={this.handleField}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    // for multi select
                                    SelectProps={item.name==="roles"?
                                        {
                                            multiple: true,
                                            renderValue: selected => selected.join(', ')
                                        }:
                                        null
                                    }
                                    autoComplete="new-password"
                                    onInput={this.validate}
                                    multiline={item.multiline}
                                    helperText={item.error}
                                    variant="outlined"
                                    InputProps={{
                                        maxLength: item.max?item.max:100,

                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            color: "red",
                                        }
                                    }}
                                    inputProps={{
                                        style: { height:18 },
                                    }}
                                >
                                    
                                    {item.name==="roles"?
                                        item.option.map((item) => (
                                            <MenuItem 
                                                 key={item.key} 
                                                 value={item.value} 
                                                 className="ListItem"
                                                 >
                                                <Checkbox color="success" checked={roles.indexOf(item.value) > -1} />
                                                <ListItemText primary={item.key} />
                                            </MenuItem>
                                        )):
                                        item.isSelect ? this.loadOptions(item.option) : null
                                    }
                                </TextField>
                            </div>
                            )}
                        </div>
                       
                       </div>
                    )}
                    
                    <div style={styles.btnContainer}>
                        <button 
                           className="customBtn clearBtn"
                           onClick={this.clearDetail}
                           >Clear</button>
                        <button 
                            className="customBtn submitBtn"
                            onClick={this.addUser}
                            >Submit</button>
                    </div>
                 </div>
                 <CustomAlert
                    open={hasError}
                    message={errorMessage}
                    type={errorType}
                    callback={this.falsifyError}
                />
            </div>
            </>
        )
    }
}

const styles={
    headingTop:{
        width:"100%",
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        marginTop:10
    },
    headingTitle:{
        color:"#404040",
        fontSize:22,
    },
    headingDetail:{
        color:"rgb(149,149,149)",
        fontSize:13,
    },
    formContainer:{
        display:"flex",
        flexWrap:'wrap'
    },
    elementMainConatiner:{
        display: "list-item",
        alignItems: "center",
        justifyContent: "start",
        padding:"5px 0 10px 5px",
        borderRadius:10,
        fontFamily:"gb Regular",
        fontStyle:"none",
        fontWeight:"800",
        background: "radial-gradient(#d6dbd73d, transparent)",
        boxShadow: "2px 3px 0px 2px #f5f1f1",
    },
    elementTitle:{
        fontSize: 16,
        fontStyle: "none",
        fontFamily: 'gb Regular',
        marginTop:5,
        marginBottom:5
    },
    btnContainer:{
        padding:"10px 10px 10px 10px",
        display:"flex",
        justifyContent:'flex-end',
        alignContent:"center",
        alignItems:"center",
        margin:10,
    }
}
export default withNavigation(CreateUserScreen);